import React, { useState } from "react";
import { postRequestGenerator } from "../../helpers/httpHelper";
import { newsStatus } from "../../helpers/enums";
import { FormattedNumber } from "react-intl";

import {
  Unstable_Grid2 as Grid,
  TextField,
  Button,
  Typography,
  Divider,
  Stack,
  InputAdornment,
  Box,
  Modal,
  Container,
} from "@mui/material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "2px solid",
  borderColor: "primary.main",
  boxShadow: 24,
  borderRadius: 3,
  pt: 2,
  px: 4,
  pb: 3,
};

const ModalInsertEmail = ({
  open,
  closeModal,
  userState,
  isAuthState,
  tokenState,
  setIsAuthState,
  setNewEmail,
}) => {
  const [emailToAdd, setEmailToAdd] = useState({
    contact: "",
    contactType: 2,
  });

  const onChangeContact = (e) => {
    setEmailToAdd({
      ...emailToAdd,
      contact: e.target.value,
    });
  };

  const onSubmit = (e) => {
    e.preventDefault();

    const addContactApi = postRequestGenerator({
      url: `aggregated/people/${userState.id}`,
      queryType: "ADD-CONTACTS",
      tokenState: tokenState,
      isAuthState: setIsAuthState,
      body: {
        contacts: [emailToAdd],
      },
    });
    addContactApi().then((e) => {
      setNewEmail(
        e.body.results[0].contacts.filter((e) => e.contactType === 2)[0]
      );
      closeModal();
    });
  };

  return (
    <Modal
      open={open}
      onClose={closeModal}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
    >
      <form onSubmit={onSubmit}>
        <Box sx={{ ...style, width: 450, maxHeight: "70vh" }}>
          <Stack spacing={2} padding={1}>
            <Typography
              color={"primary.main"}
              id="parent-modal-title"
              sx={{
                fontSize: "1.2rem",
                fontWeight: 500,
              }}
            >
              Aggiungi contatto
            </Typography>
            <Divider />
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                verticalAlign: "middle",
              }}
            >
              {" "}
              <Container>
                <Grid
                  container
                  spacing={1}
                  alignItems={"center"}
                  justifyContent={"center"}
                >
                  <Grid item xs={6}>
                    <Typography
                      variant="body1"
                      id="parent-modal-description"
                      sx={{
                        fontSize: ".95rem",
                        fontWeight: 400,
                        color: "#626262",
                      }}
                    >
                      {"Aggiungi email"}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      value={emailToAdd.contact}
                      onChange={onChangeContact}
                      required={true}
                      color="primary"
                      size="small"
                      type="email"
                      name="contact"
                    ></TextField>
                  </Grid>
                </Grid>
              </Container>
            </Box>
            <Divider />
            <Stack direction={"row-reverse"} justifyItems={"flex-end"}>
              <Button
                type="submit"
                sx={{ color: "white" }}
                variant="contained"
                onSubmit={onSubmit}
              >
                {"INVIA"}
              </Button>
              <Button
                size="small"
                variant="outlined"
                onClick={closeModal}
                sx={{
                  marginRight: 2,
                }}
              >
                {"INDIETRO"}
              </Button>
            </Stack>
          </Stack>
        </Box>
      </form>
    </Modal>
  );
};

export default ModalInsertEmail;
