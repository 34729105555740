import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  TextField,
  Unstable_Grid2 as Grid,
} from "@mui/material";

import { getRequestGenerator } from "../../helpers/httpHelper";

import ContentNotInvoiceable from "./ContentNotInvoiceable";
import ContentInvoiceable from "./ContentInvoiceable";

const CardGenerateInvoice = ({ tokenState, isAuthState, setIsAuthState }) => {
  const [invoiceableState, setInvoiceableState] = useState({
    isInvoiceable: false,
    nextStartDate: undefined,
    nextEndDate: undefined,
  });

  const variant = "outlined";

  useEffect(() => {
    if (isAuthState) {
      const getIsInvoiceableApi = getRequestGenerator({
        url: `microservices/invoices`,
        queryType: "IS-INVOICEABLE",
        tokenState: tokenState,
        isAuthState: setIsAuthState,
      });
      getIsInvoiceableApi().then((e) => {
        setInvoiceableState(e.body);
      });
    }
  }, [isAuthState]);

  return (
    <Card
      sx={{
        boxShadow:
          "0px 5px 22px rgba(0, 0, 0, 0.05), 0px 0px 0px 0.5px rgba(0, 0, 0, 0.035)",
        borderRadius: 3,
        height: "100%",
      }}
    >
      <CardHeader
        subheader={``}
        title="calcolatore fattura"
        titleTypographyProps={{
          variant: "overline",
          fontSize: ".75rem",
          color: "text.secondary",
        }}
        subheaderTypographyProps={{
          variant: "caption",
          fontSize: ".75rem",
          color: "#616161",
          mt: "-.55rem",
          mb: ".0rem",
        }}
      />
      {!invoiceableState.isInvoiceable && (
        <ContentNotInvoiceable
          nextStartDate={invoiceableState.nextStartDate}
          nextEndDate={invoiceableState.nextEndDate}
        />
      )}
      {invoiceableState.isInvoiceable && (
        <ContentInvoiceable
          tokenState={tokenState}
          isAuthState={isAuthState}
          setIsAuthState={setIsAuthState}
        />
      )}

      {/* <Divider /> */}
      <CardActions sx={{ justifyContent: "flex-end" }}></CardActions>
    </Card>
  );
};

export default CardGenerateInvoice;
