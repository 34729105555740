import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Stack,
  Container,
  CardContent,
  Unstable_Grid2 as Grid,
  Button,
} from "@mui/material";

import { FormattedNumber, FormattedDate } from "react-intl";
import {
  getRequestGenerator,
  postRequestGenerator,
} from "../../helpers/httpHelper";
import InvoiceModal from "./InvoiceModal";
import { useNavigate } from "react-router-dom";

const ContentInvoiceable = ({ tokenState, isAuthState, setIsAuthState }) => {
  const [invoiceableData, setInvoiceableData] = useState({
    invoiceableAmount: 0.0,
    monthOfCompetence: undefined,
  });
  const [open, setOpen] = useState(false);
  const [invoiceData, setInvoiceData] = useState({
    invoiceNumber: undefined,
  });
  const navigate = useNavigate();

  useEffect(() => {
    if (isAuthState) {
      const getInvoiceableData = getRequestGenerator({
        url: `microservices/invoices`,
        queryType: "GET-INVOICEABLE-AMOUNT",
        tokenState: tokenState,
        isAuthState: setIsAuthState,
      });
      getInvoiceableData().then((e) => {
        setInvoiceableData(e.body);
      });
    } else {
      return;
    }
  }, [isAuthState]);

  const closeModal = (e) => {
    setOpen(false);
    e.preventDefault();
  };

  const sendInvoiceNumber = (e) => {
    const setInvoicedApi = postRequestGenerator({
      url: "microservices/invoices",
      queryType: "SET-INVOICED",
      tokenState: tokenState,
      isAuthState: setIsAuthState,
      body: invoiceData,
    });
    setInvoicedApi().then((e) => {
      setInvoiceableData({ ...invoiceableData, invoiceableAmount: 0.0 });
      setOpen(false);
      navigate("/portafoglio");
    });

    e.preventDefault();
  };

  const onChangeField = (e) => {
    setInvoiceData({ invoiceNumber: e.target.value });
    e.preventDefault();
  };

  const { invoiceableAmount } = invoiceableData;
  // const invoiceableAmount = 330;

  return (
    <>
      <CardContent>
        <Stack
          direction={"row"}
          justifyContent={"space-around"}
          alignItems={"center"}
          spacing={2}
          padding={2}
        >
          <Stack
            spacing={3}
            alignItems={"center"}
            justifyContent={"space-around"}
          >
            <Typography
              variant="body2"
              sx={{
                fontSize: "1.4rem",
                color: "primary.main",
                fontWeight: 500,
              }}
            >
              {" "}
              IMPORTO FATTURABILE <FormattedNumber value={invoiceableAmount} />€
              <Typography
                component={"span"}
                sx={{
                  display: "block",
                  my: ".4rem",
                  fontSize: ".76rem",
                  color: "#757575",
                  textAlign: "center",
                }}
              >
                Compensi realizzati fino al{" "}
                <FormattedDate value={invoiceableData.monthOfCompetence} />
              </Typography>
            </Typography>

            {/* {invoiceableAmount > 0 && (
              <Button
                color="primary"
                variant="outlined"
                onClick={() => setOpen(true)}
              >
                {"RICHIEDI PAGAMENTO"}
              </Button>
            )} */}
          </Stack>
        </Stack>
      </CardContent>
      <InvoiceModal
        open={open}
        closeModal={closeModal}
        sendInvoiceNumber={sendInvoiceNumber}
        onChangeField={onChangeField}
        invoiceNumber={invoiceData.invoiceNumber}
      />
    </>
  );
};

const months = ["Gennaio", "Febbraio", "Marzo", "Aprile", "Maggio"];

export default ContentInvoiceable;
