import React, { useState } from "react";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  TextField,
  InputAdornment,
  Typography,
  Unstable_Grid2 as Grid,
} from "@mui/material";

import { postRequestGenerator } from "../../helpers/httpHelper";

const CardRequiredPriceDetails = ({
  userState,
  tokenState,
  setIsAuthState,
  houseData,
  registrationDate,
  setIsModified,
}) => {
  const [modifiedValues, setModifiedValues] = useState({});
  const [sent, setSent] = useState(false);
  const variant = "outlined";

  const onChangeField = (e) => {
    setModifiedValues({
      ...modifiedValues,
      [e.target.name]: e.target.value.trim(),
      // username: userState.username,
    });

    e.preventDefault();
  };

  const onSubmit = (e) => {
    e.preventDefault();

    const patchAddress = postRequestGenerator({
      url: `atomic/houses/${houseData.id}`,
      queryType: "PATCH",
      tokenState: tokenState,
      isAuthState: setIsAuthState,
      body: modifiedValues,
    });
    patchAddress().then((e) => {
      setSent(true);
      setIsModified(true);
    });
  };

  return (
    <Card
      sx={{
        boxShadow:
          "0px 5px 22px rgba(0, 0, 0, 0.05), 0px 0px 0px 0.5px rgba(0, 0, 0, 0.035)",
        borderRadius: 3,
      }}
    >
      {" "}
      <form autoComplete="off" noValidate onSubmit={onSubmit}>
        <CardHeader
          title={"Richiesta cliente"}
          subheader={`Notizia del ${formatDate(userState.registrationDate)}`}
          titleTypographyProps={{
            variant: "overline",
            fontSize: "1.15rem",
            color: "#424242",
            mb: "-.30rem",
          }}
          subheaderTypographyProps={{
            variant: "caption",
            fontSize: ".75rem",
            color: "#616161",
            mt: "-.55rem",
            mb: ".0rem",
          }}
        />
        <CardContent sx={{ pt: 0 }}>
          <Box sx={{ m: -1.5 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12}>
                <TextField
                  value={
                    modifiedValues.requiredPrice ??
                    houseData?.requiredPrice ??
                    ""
                  }
                  onChange={onChangeField}
                  fullWidth={true}
                  color={
                    (modifiedValues.requiredPrice?.length ?? "") > 0 && sent
                      ? "success"
                      : "primary"
                  }
                  focused={
                    (modifiedValues.requiredPrice?.length ?? "") > 0 && sent
                  }
                  size="small"
                  type="text"
                  name="requiredPrice"
                  label="richiesta"
                  variant={variant}
                  InputProps={{
                    inputMode: "numeric",
                    pattern: "[0-9]*",
                    endAdornment: (
                      <InputAdornment
                        position="end"
                        sx={{
                          color: "primary.main",
                        }}
                      >
                        <Typography>{"€"}</Typography>
                      </InputAdornment>
                    ),
                  }}
                ></TextField>
              </Grid>
            </Grid>
          </Box>
        </CardContent>
        <Divider />
        <CardActions sx={{ justifyContent: "flex-end" }}>
          <Button
            type="submit"
            variant={sent ? "outlined" : "contained"}
            sx={{ color: sent ? "primary" : "white" }}
            color="primary"
          >
            {sent ? "SALVATO" : "SALVA"}{" "}
          </Button>
        </CardActions>
      </form>
    </Card>
  );
};

function formatDate(date) {
  const dateObject = new Date(date);
  return `${dateObject.getDate()}/${dateObject.getMonth()}/${dateObject.getFullYear()}`;
}

export default CardRequiredPriceDetails;
