import React, { useState } from "react";
import { postRequestGenerator } from "../../helpers/httpHelper";
import { newsStatus } from "../../helpers/enums";
import { FormattedNumber } from "react-intl";

import {
  Unstable_Grid2 as Grid,
  TextField,
  Button,
  Typography,
  Divider,
  Stack,
  InputAdornment,
  Box,
  Modal,
  Container,
} from "@mui/material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "2px solid",
  borderColor: "primary.main",
  boxShadow: 24,
  borderRadius: 3,
  pt: 2,
  px: 4,
  pb: 3,
};

const ModalAcquireNews = ({
  open,
  closeModal,
  newsData,
  id,
  isAuthState,
  tokenState,
  setIsAuthState,
  setNewsData,
}) => {
  const newsItem = newsData.filter((e) => e.news.id === id)[0];
  const [houseDataToPatch, setHouseDataToPatch] = useState({});
  const [newsDataToPatch, setNewsDataToPatch] = useState({
    newsStatus: newsStatus.indexOf("Rogito Fatto"),
    sellingDate: Date.now(),
  });

  const onChangeHouse = (e) => {
    setHouseDataToPatch({
      ...houseDataToPatch,
      [e.target.name]: e.target.value,
    });
  };

  const onChangeNews = (e) => {
    setNewsDataToPatch({
      ...newsDataToPatch,
      [e.target.name]: e.target.value,
    });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (isAuthState) {
      const patchNewsApi = postRequestGenerator({
        url: `atomic/news/${id}`,
        queryType: "PATCH",
        tokenState: tokenState,
        isAuthState: setIsAuthState,
        body: {
          ...newsDataToPatch,
        },
      });
      const patcHouseApi = postRequestGenerator({
        url: `atomic/houses/${newsItem.house.id}`,
        queryType: "PATCH",
        tokenState: tokenState,
        isAuthState: setIsAuthState,
        body: { ...houseDataToPatch },
      });
      Promise.all([patchNewsApi(), patcHouseApi()]).then(() => {
        setNewsData(newsData.filter((e) => e.news.id !== id));
        closeModal();
      });
    }
  };

  return (
    <Modal
      open={open}
      onClose={closeModal}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
    >
      <form onSubmit={onSubmit}>
        <Box sx={{ ...style, width: 450, maxHeight: "70vh" }}>
          <Stack spacing={2} padding={1}>
            <Typography
              color={"primary.main"}
              id="parent-modal-title"
              sx={{
                fontSize: "1.2rem",
                fontWeight: 500,
              }}
            >
              Dati di vendita
            </Typography>
            <Divider />
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                verticalAlign: "middle",
              }}
            >
              {" "}
              <Container>
                <Grid
                  container
                  spacing={1}
                  alignItems={"center"}
                  justifyContent={"center"}
                >
                  <Grid item xs={6}>
                    <Typography
                      variant="body1"
                      id="parent-modal-description"
                      sx={{
                        fontSize: ".95rem",
                        fontWeight: 400,
                        color: "#626262",
                      }}
                    >
                      {"Prezzo vendita effettivo"}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      value={houseDataToPatch.selledPrice ?? ""}
                      onChange={onChangeHouse}
                      required={true}
                      color="primary"
                      size="small"
                      type="text"
                      name="selledPrice"
                      InputProps={{
                        inputMode: "numeric",
                        pattern: "[0-9]*",
                        endAdornment: (
                          <InputAdornment
                            position="end"
                            sx={{
                              color: "primary.main",
                            }}
                          >
                            <Typography>€</Typography>
                          </InputAdornment>
                        ),
                      }}
                    ></TextField>
                  </Grid>
                  {/* <Grid item xs={6}>
                    <Typography
                      variant="body1"
                      id="parent-modal-description"
                      sx={{
                        fontSize: ".95rem",
                        fontWeight: 400,
                        color: "#626262",
                      }}
                    >
                      {"Provvigione Concordata"}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      value={
                        newsDataToPatch.agreedCommission ??
                        newsItem?.news?.agreedCommission ??
                        0.0
                      }
                      onChange={onChangeNews}
                      required={true}
                      color="primary"
                      size="small"
                      type="text"
                      name="agreedCommission"
                      InputProps={{
                        inputMode: "numeric",
                        pattern: "[0-9]*",
                        endAdornment: (
                          <InputAdornment
                            position="end"
                            sx={{
                              color: "primary.main",
                            }}
                          >
                            <Typography>%</Typography>
                          </InputAdornment>
                        ),
                      }}
                    ></TextField>
                  </Grid> */}
                </Grid>
              </Container>
            </Box>
            <Divider />
            <Stack direction={"row-reverse"} justifyItems={"flex-end"}>
              <Button
                type="submit"
                sx={{ color: "white" }}
                variant="contained"
                onSubmit={onSubmit}
              >
                {"INVIA"}
              </Button>
              <Button
                size="small"
                variant="outlined"
                onClick={closeModal}
                sx={{
                  marginRight: 2,
                }}
              >
                {"INDIETRO"}
              </Button>
            </Stack>
          </Stack>
        </Box>
      </form>
    </Modal>
  );
};

export default ModalAcquireNews;
