import React, { useMemo, useState } from "react";
import { Card, CardActions, CardContent, CardHeader } from "@mui/material";
import {
  DataGrid,
  itIT,
  GridToolbar,
  GridActionsCellItem,
} from "@mui/x-data-grid";

import { user, token, isAuth } from "../../athoms/auth";
import { useRecoilState, useRecoilValue } from "recoil";
import { postRequestGenerator } from "../../helpers/httpHelper";

import { useNavigate } from "react-router-dom";
import TableContractAction from "../TableContractAction";
import ModalContract from "./ModalContract";
import TableDeleteAction from "../TableDeleteAction";

const TableAnagraficaPending = ({ personData, setPeopleData }) => {
  const [paginationState, setPaginationState] = useState(10);
  //   const navigate = useNavigate();
  const [open, setOpen] = useState({ open: false, personData: {} });
  const tokenState = useRecoilValue(token);
  const [isAuthState, setIsAuthState] = useRecoilState(isAuth);

  const recordsFromApi = personData.map((e) => {
    return {
      id: e.people.id,
      visual: e.people.visual,
      telephone:
        e.contacts.filter(
          (e) => e?.contactType === 0 || e?.contactType === 1
        )[0]?.contact ?? "",
      email: e.contacts.filter((e) => e?.contactType === 2)[0]?.contact ?? "",
      level:
        e.personJobs[0].jobType === 4
          ? "Nessuno"
          : e.personalLevel?.name ?? "Nessuno",
      isActive: e.people.status === 1,
      fullObject: e,
      commercialFlag: e.people.commercialFlagExtId,
    };
  });

  const onCLick = (data, e) => {
    e.preventDefault();
    // navigate(`/anagrafica/${data.id}`, {
    //   state: personData.filter((e) => e.people.id === data.id)[0],
    // });
  };

  const onAdd = (params) => {
    return () => {
      setOpen({
        open: true,
        personData: params.row.fullObject,
      });
    };
  };

  const onRemove = (params) => {
    return () => {
      const deleteRecordApi = postRequestGenerator({
        url: `atomic/people/${params.row.fullObject.people.id}`,
        queryType: "DELETE-NO-CONTRACT",
        tokenState: tokenState,
        isAuthState: setIsAuthState,
        body: {},
      });
      deleteRecordApi().then(() => deleteRow(params.row.fullObject.people.id));
    };
  };

  const deleteRow = (rowId) => {
    setPeopleData(personData.filter((e) => e.id === rowId));
  };

  const closeModal = () => setOpen({ open: false });

  const columns = useMemo(
    () => [
      {
        field: "id",
        headerName: "ID",
        width: 66.8,
      },
      {
        field: "level",
        headerName: "LIVELLO",
        width: 170,
      },

      {
        field: "visual",
        headerName: "NOME",
        width: 250,
      },
      {
        field: "telephone",
        headerName: "TELEFONO",
        width: 260,
      },
      {
        field: "email",
        headerName: "EMAIL",
        width: 280,
      },
      {
        field: "commercialFlag",
        headerName: "SEGNALATORE",
        width: 150,
      },
      {
        field: "delete",
        type: "actions",
        getActions: (params) => {
          return [
            <GridActionsCellItem
              icon={<TableContractAction />}
              onClick={onAdd(params)}
              label="pay"
            />,
          ];
        },
      },
      {
        field: "remove",
        type: "actions",
        getActions: (params) => {
          return [
            <GridActionsCellItem
              icon={<TableDeleteAction />}
              onClick={onRemove(params)}
              label="remove"
            />,
          ];
        },
      },
    ],
    [personData]
  );

  return (
    <>
      <DataGrid
        showCellVerticalBorder={false}
        localeText={itIT.components.MuiDataGrid.defaultProps.localeText}
        columns={columns}
        rows={recordsFromApi}
        getRowId={(row) => row.id}
        initialState={{
          ...recordsFromApi,
          pagination: {
            paginationModel: { pageSize: paginationState, page: 0 },
          },
        }}
        pageSizeOptions={[5, 10, 20, 50]}
        sx={{
          boxShadow:
            "0px 5px 22px rgba(0, 0, 0, 0.05), 0px 0px 0px 0.5px rgba(0, 0, 0, 0.035)",
          borderRadius: 3,
          border: 0,
        }}
        onRowDoubleClick={onCLick}
        slots={{
          toolbar: () => <GridToolbar sx={{ mb: 2 }} />,
        }}
      />
      {open.open && (
        <ModalContract
          open={open.open}
          handleClose={closeModal}
          personData={open.personData}
          deleteRow={deleteRow}
        />
      )}
    </>
  );
};

export default TableAnagraficaPending;
