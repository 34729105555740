import { Container, SvgIcon } from "@mui/material";
import React from "react";

import DocumentCheckIcon from "@heroicons/react/24/outline/DocumentCheckIcon";

const TableContractAction = ({ onUpdate }) => {
  return (
    <SvgIcon color="success" onClick={onUpdate} sx={{ cursor: "pointer" }}>
      <DocumentCheckIcon />
    </SvgIcon>
  );
};

export default TableContractAction;