import UsersIcon from "@heroicons/react/24/solid/QuestionMarkCircleIcon";
import {
  Avatar,
  Card,
  CardContent,
  Stack,
  SvgIcon,
  Typography,
  Badge,
  Tooltip,
  useTheme,
} from "@mui/material";
import { FormattedNumber } from "react-intl";

const CardRealGain = (props) => {
  const theme = useTheme();
  const { sx, earnings } = props;
  return (
    <Card sx={sx}>
      <CardContent>
        <Stack
          alignItems="flex-start"
          direction="row"
          justifyContent="space-between"
          spacing={3}
        >
          <Stack spacing={1}>
            <Typography color="text.secondary" variant="overline">
              guadagno realizzato
            </Typography>

            <Typography variant="h4" color={"#424242"}>
              <FormattedNumber value={earnings.realGain} />
              {" €"}
            </Typography>
          </Stack>
          <Tooltip
            title="Valore delle segnalazioni vendute. Sarà fatturabile i primi 5 giorni del mese"
            placement="top-end"
          >
            <SvgIcon>
              <UsersIcon style={{ color: theme.palette.primary.main }} />
            </SvgIcon>
          </Tooltip>
          {/* <Avatar
            sx={{
              backgroundColor: "childrenInProduction.main",
              height: 56,
              width: 56,
            }}
          >
            <SvgIcon>
              <UsersIcon />
            </SvgIcon>
          </Avatar> */}
        </Stack>
      </CardContent>
    </Card>
  );
};

// function generateInvoiceDateOrButton() {
//   const dateNow = new Date();
//   if (!dateNow.getDate() >= 5) {
//     console.log("bottone");
//     return "bottone";
//   } else {
//     const newDate = new Date(dateNow);
//     newDate.setMonth(dateNow.getMonth() + 1);
//     newDate.setDate(1);
//     return (
//       <Typography variant="overline" color={"#757575"}>
//         Fatturabile dal
//         <FormattedDate value={newDate} />
//       </Typography>
//     );
//   }
// }

export default CardRealGain;
