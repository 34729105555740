import ComputerDesktopIcon from "@heroicons/react/24/solid/ComputerDesktopIcon";
import DeviceTabletIcon from "@heroicons/react/24/solid/DeviceTabletIcon";
import PhoneIcon from "@heroicons/react/24/solid/PhoneIcon";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Container,
  Stack,
  SvgIcon,
  Typography,
  useTheme,
} from "@mui/material";
import ApexChart from "react-apexcharts";

const Settings = (labels) => {
  const theme = useTheme();

  return {
    chart: {
      background: "transparent",
    },
    colors: [
      theme.palette.news.main,
      theme.palette.child.main,
      theme.palette.childrenInProduction.main,
    ],
    dataLabels: {
      enabled: true,
    },
    labels,
    legend: {
      show: true,
    },
    plotOptions: {
      pie: {
        expandOnClick: false,
      },
    },
    states: {
      active: {
        filter: {
          type: "none",
        },
      },
      hover: {
        filter: {
          type: "none",
        },
      },
    },
    stroke: {
      width: 0,
    },
    theme: {
      mode: theme.palette.mode,
    },
    tooltip: {
      fillSeriesColor: false,
    },
  };
};

const CardPieNewsChart = (props) => {
  const { labels, sx, data } = props;
  const chartOptions = Settings(labels);
  const chartSeries = [...data];

  return (
    <Card sx={sx}>
      <Stack spacing={1}>
        <Container sx={{ marginTop: ".95rem" }}>
          <Typography color="text.secondary" variant="overline">
            Proporzione
          </Typography>
        </Container>
        <CardContent>
          <ApexChart
            height={"100%"}
            options={chartOptions}
            series={chartSeries}
            type="donut"
            width="100%"
          />
          <Stack
            alignItems="center"
            direction="row"
            justifyContent="center"
            spacing={2}
            sx={{ mt: 2 }}
          >
            {chartSeries.map((item, index) => {
              const label = labels[index];

              return (
                <Box
                  key={label}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                ></Box>
              );
            })}
          </Stack>
        </CardContent>
      </Stack>
    </Card>
  );
};

export default CardPieNewsChart;
