import React, { useState } from "react";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  TextField,
  Unstable_Grid2 as Grid,
} from "@mui/material";

import { postRequestGenerator } from "../../helpers/httpHelper";

const CardPersonDetails = ({
  personData,
  userState,
  tokenState,
  setIsAuthState,
  houseOwner,
}) => {
  const [modifiedValues, setModifiedValues] = useState({});
  const [sent, setSent] = useState(false);
  const variant = "outlined";

  const onChangeField = (e) => {
    setModifiedValues({
      ...modifiedValues,
      [e.target.name]: e.target.value,
    });
    e.preventDefault();
  };

  const onSubmit = (e) => {
    const patchPerson = postRequestGenerator({
      url: `atomic/people/${userState.id}`,
      queryType: "PATCH",
      tokenState: tokenState,
      isAuthState: setIsAuthState,
      body: { ...modifiedValues },
    });
    patchPerson()
      .then(() => setSent(true))
      .catch((e) => console.log(e));

    e.preventDefault();
  };

  return (
    <Card
      sx={{
        boxShadow:
          "0px 5px 22px rgba(0, 0, 0, 0.05), 0px 0px 0px 0.5px rgba(0, 0, 0, 0.035)",
        borderRadius: 3,
      }}
    >
      {" "}
      <form autoComplete="off" noValidate onSubmit={onSubmit}>
        <CardHeader
          subheader={
            houseOwner
              ? `Segnalato il ${formatDate(personData.registrationDate)}`
              : `Utente dal ${formatDate(personData.registrationDate)}`
          }
          title={houseOwner ? "Dati Proprietario" : "Dati personali"}
          titleTypographyProps={{
            variant: "overline",
            fontSize: "1.15rem",
            color: "#424242",
          }}
          subheaderTypographyProps={{
            variant: "caption",
            fontSize: ".75rem",
            color: "#616161",
            mt: "-.55rem",
            mb: ".0rem",
          }}
        />
        <CardContent sx={{ pt: 0 }}>
          <Box sx={{ m: -1.5 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12}>
                <TextField
                  value={personTypeEnum()[personData.personType] ?? ""}
                  fullWidth={true}
                  aria-readonly={true}
                  color="primary"
                  size="small"
                  type="text"
                  name="personType"
                  label="tipo"
                  variant={variant}
                ></TextField>
              </Grid>
              <Grid
                item
                xs={12}
                md={personData.personType === 1 ? 12 : 6}
                hidden={personData.personType === 0}
              >
                <TextField
                  value={
                    modifiedValues?.businessName ??
                    personData.businessName ??
                    ""
                  }
                  onChange={onChangeField}
                  fullWidth={true}
                  color={
                    (modifiedValues?.businessName?.length ?? "") > 0 && sent
                      ? "success"
                      : "primary"
                  }
                  focused={
                    (modifiedValues?.businessName?.length ?? "") > 0 && sent
                  }
                  size="small"
                  type="text"
                  name="businessName"
                  label="ragione sociale"
                  variant={variant}
                ></TextField>
              </Grid>
              <Grid item xs={12} md={6} hidden={personData.personType === 2}>
                <TextField
                  value={modifiedValues?.name ?? personData.name ?? ""}
                  onChange={onChangeField}
                  fullWidth={true}
                  color={
                    (modifiedValues?.name?.length ?? "") > 0 && sent
                      ? "success"
                      : "primary"
                  }
                  focused={(modifiedValues?.name?.length ?? "") > 0 && sent}
                  size="small"
                  type="text"
                  name="name"
                  label="nome"
                  variant={variant}
                ></TextField>
              </Grid>
              <Grid item xs={12} md={6} hidden={personData.personType === 2}>
                <TextField
                  value={modifiedValues?.surname ?? personData.surname ?? ""}
                  onChange={onChangeField}
                  fullWidth={true}
                  color={
                    (modifiedValues?.surname?.length ?? "") > 0 && sent
                      ? "success"
                      : "primary"
                  }
                  focused={(modifiedValues?.surname?.length ?? "") > 0 && sent}
                  size="small"
                  type="text"
                  name="surname"
                  label="cognome"
                  variant={variant}
                ></TextField>
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  value={
                    modifiedValues?.fiscalCode ?? personData.fiscalCode ?? ""
                  }
                  onChange={onChangeField}
                  fullWidth={true}
                  color={
                    (modifiedValues?.fiscalCode?.length ?? "") > 0 && sent
                      ? "success"
                      : "primary"
                  }
                  focused={
                    (modifiedValues?.fiscalCode?.length ?? "") > 0 && sent
                  }
                  size="small"
                  type="text"
                  name="fiscalCode"
                  label={
                    personData.personType === 2
                      ? "codice fiscale/p.iva"
                      : "codice fiscale"
                  }
                  variant={variant}
                ></TextField>
              </Grid>
              <Grid item xs={12} md={6} hidden={personData.personType !== 1}>
                <TextField
                  value={modifiedValues?.vat ?? personData.vat ?? ""}
                  onChange={onChangeField}
                  fullWidth={true}
                  color={
                    (modifiedValues?.vat?.length ?? "") > 0 && sent
                      ? "success"
                      : "primary"
                  }
                  focused={(modifiedValues?.vat?.length ?? "") > 0 && sent}
                  size="small"
                  type="text"
                  name="vat"
                  label="p.iva"
                  variant={variant}
                ></TextField>
              </Grid>
              <Grid item xs={12} md={personData.personType === 0 ? 6 : 12}>
                <TextField
                  value={modifiedValues?.iban ?? personData.iban ?? ""}
                  onChange={onChangeField}
                  fullWidth={true}
                  color={
                    (modifiedValues?.iban?.length ?? "") > 0 && sent
                      ? "success"
                      : "primary"
                  }
                  focused={(modifiedValues?.iban?.length ?? "") > 0 && sent}
                  size="small"
                  type="text"
                  name="iban"
                  label="iban"
                  variant={variant}
                ></TextField>
              </Grid>
            </Grid>
          </Box>
        </CardContent>
        <Divider />
        <CardActions sx={{ justifyContent: "flex-end" }}>
          <Button
            type="submit"
            variant={sent ? "outlined" : "contained"}
            sx={{ color: sent ? "primary" : "white" }}
            color="primary"
          >
            {sent ? "SALVATO" : "SALVA"}{" "}
          </Button>
        </CardActions>
      </form>
    </Card>
  );
};

function personTypeEnum() {
  return ["Persona Fisica", "Ditta Individuale", "Società", "Nessuno"];
}

function emptyGrid(modifiedValues, personData) {
  if (modifiedValues.personType !== 0 || personData.personType !== 0) {
    return (
      <Grid
        item
        xs={12}
        md={6}
        hidden={modifiedValues.personType === 0 || personData.personType === 0}
      ></Grid>
    );
  }
}

function formatDate(date) {
  const dateObject = new Date(date);
  return `${dateObject.getDate()}/${dateObject.getMonth()}/${dateObject.getFullYear()}`;
}

export default CardPersonDetails;
