import React, { useEffect, useMemo, useState } from "react";
import {
  DataGrid,
  itIT,
  GridToolbar,
  GridActionsCellItem,
} from "@mui/x-data-grid";
import { FormattedDate, FormattedNumber } from "react-intl";
import { postRequestGenerator } from "../../helpers/httpHelper";

import TablePaymentAction from "../TablePaymentAction";

const TablePayments = ({
  paymentsData,
  setPaymentsData,
  tokenState,
  setIsAuthState,
  isAuthState,
}) => {
  const [paginationState, setPaginationState] = useState(10);
  const [okToSend, setOkToSend] = useState(false);

  const recordsFromApi = useMemo(
    () =>
      paymentsData.map((e) => {
        return {
          commercialFlagId: e.commercialFlagId,
          amount: e.amount,
          commercialFlagVisual: e.commercialFlagVisual,
          monthsOfCompetence: e.monthsOfCompetence,
          invoices: e.invoices.join(" "),
          treeSnapshotIds: e.treeSnapshotIds.join(","),
        };
      }),

    [paymentsData]
  );

  const onCLick = (data, e) => {
    e.preventDefault();
    // navigate(`/anagrafica/${data.id}`, {
    //   state: paymentsData.filter((e) => e.people.id === data.id)[0],
    // });
  };

  const columns = useMemo(
    () => [
      {
        field: "commercialFlagId",
        headerName: "ID",
        width: 66.8,
      },
      {
        field: "commercialFlagVisual",
        headerName: "SEGNALATORE",
        width: 300,
      },

      {
        field: "monthsOfCompetence",
        headerName: "COMPETENZA",
        width: 250,
        renderCell: (e) => (
          <>
            {"Fino al"} <FormattedDate value={e.value} />{" "}
          </>
        ),
      },
      // {
      //   field: "treeSnapshotIds",
      //   headerName: "IdsInternal",
      //   width: 200,
      // },
      {
        field: "invoices",
        headerName: "FATTURE",
        width: 300,
      },
      {
        field: "amount",
        headerName: "TOTALE",
        width: 190,
        renderCell: (e) => (
          <>
            <FormattedNumber value={e.value} />
            {" €"}
          </>
        ),
      },
      {
        field: "pay",
        type: "actions",
        getActions: (params) => {
          return [
            <GridActionsCellItem
              icon={<TablePaymentAction />}
              onClick={onSell(params)}
              label="pay"
            />,
          ];
        },
      },
    ],
    []
  );

  const onSell = (params) => {
    return () => {
      const setPaidApi = postRequestGenerator({
        url: `microservices/payments`,
        queryType: "SET-PAID",
        tokenState: tokenState,
        isAuthState: setIsAuthState,
        body: {
          treeSnapshotIds: params.row.treeSnapshotIds,
        },
      });
      setPaidApi().then((r) => setOkToSend(params.id));
    };
  };

  useEffect(() => {
    if (okToSend !== false) {
      setPaymentsData(
        paymentsData.filter((e) => e.commercialFlagId !== okToSend)
      );
    }
  }, [okToSend]);

  return (
    <DataGrid
      showCellVerticalBorder={false}
      localeText={itIT.components.MuiDataGrid.defaultProps.localeText}
      columns={columns}
      rows={recordsFromApi}
      getRowId={(row) => row.commercialFlagId}
      initialState={{
        ...recordsFromApi,
        pagination: { paginationModel: { pageSize: paginationState, page: 0 } },
      }}
      pageSizeOptions={[5, 10, 20, 50]}
      sx={{
        boxShadow:
          "0px 5px 22px rgba(0, 0, 0, 0.05), 0px 0px 0px 0.5px rgba(0, 0, 0, 0.035)",
        borderRadius: 3,
        border: 0,
      }}
      onRowDoubleClick={onCLick}
      slots={{
        toolbar: () => <GridToolbar sx={{ mb: 2 }} />,
      }}
    />
  );
};

export default TablePayments;
