import HomeModernIcon from "@heroicons/react/24/solid/HomeModernIcon";
import UsersIcon from "@heroicons/react/24/solid/UsersIcon";
import {
  Avatar,
  Card,
  CardContent,
  Stack,
  SvgIcon,
  Typography,
} from "@mui/material";

const CardMyNews = (props) => {
  const { sx, myNews, childNews } = props;

  return (
    <Card sx={sx}>
      <CardContent>
        <Stack
          alignItems="flex-start"
          direction="row"
          justifyContent="space-between"
          spacing={3}
        >
          <Stack spacing={1}>
            <Typography
              color="text.secondary"
              variant="overline"
              sx={{ lineHeight: "1rem" }}
            >
              {myNews != null
                ? "mie notizie verificate"
                : "notizie dei segnalatori verificate"}
            </Typography>
            <Typography variant="h4">{`${
              myNews ?? childNews ?? "--"
            }`}</Typography>
          </Stack>
          <Avatar
            sx={{
              backgroundColor: myNews == null ? "child.main" : "news.main",
              height: 56,
              width: 56,
            }}
          >
            <SvgIcon>
              <HomeModernIcon />
            </SvgIcon>
          </Avatar>
        </Stack>
      </CardContent>
    </Card>
  );
};

export default CardMyNews;
