import { Container, SvgIcon } from "@mui/material";
import React from "react";

import BanknotesIcon from "@heroicons/react/24/outline/BanknotesIcon";

const TablePaymentAction = () => {
  return (
    <SvgIcon
      color="primary"
      sx={{
        width: "1.35rem",
      }}
    >
      <BanknotesIcon />
    </SvgIcon>
  );
};

export default TablePaymentAction;
