import React, { useState } from "react";
import { postRequestGenerator } from "../../helpers/httpHelper";

import {
  Unstable_Grid2 as Grid,
  TextField,
  Button,
  Typography,
  Divider,
  Stack,
  Checkbox,
  Box,
  Modal,
} from "@mui/material";
import { Link } from "react-router-dom";

const Step3 = ({
  step,
  setStep,
  onChangeField,
  formData,
  variant,
  submitHandler,
  setFormData,
}) => {
  const [usernameAvaiable, setUsernameAvaiable] = useState(true);
  const [open, setOpen] = useState(false);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid",
    borderColor: "primary.main",
    boxShadow: 24,
    borderRadius: 3,
    pt: 2,
    px: 4,
    pb: 3,
  };

  const onChangeUsername = (e) => {
    setUsernameAvaiable(true);
    onChangeField(e);
    e.preventDefault();
  };

  const onBlurUsername = () => {
    const checkUsernameAvaiability = postRequestGenerator({
      url: "commercialFlag/signUp",
      queryType: "CHECK-USERNAME",
      tokenState: "",
      body: {
        people: {
          commercialFlagExtId: formData.commercialFlagExtId,
          permission: formData.permission,
        },
        username: formData.username.trim(),
      },
      isAuthState: (bool) => bool,
    });
    if (formData.username.length > 0) {
      checkUsernameAvaiability().then((e) =>
        setUsernameAvaiable(e.body.results)
      );
    }
  };

  const decreaseStep = () => {
    setStep(step - 1);
  };

  const closeModal = (e) => {
    setOpen(false);
    e.preventDefault();
  };

  const acceptEula = (e) => {
    setFormData({
      ...formData,
      eula: true,
    });
    closeModal(e);
    e.preventDefault();
  };

  return (
    <>
      <form onSubmit={submitHandler}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12} textAlign={"center"}>
            <Typography variant="h6">DATI UTENTE</Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              error={!usernameAvaiable}
              helperText={
                !usernameAvaiable
                  ? `${formData.username} già in uso: sceglierne un altro.`
                  : ""
              }
              onBlur={onBlurUsername}
              value={formData.username}
              onChange={onChangeUsername}
              required
              fullWidth={true}
              color="primary"
              size="small"
              type="text"
              name="username"
              label="username"
              variant={variant}
            ></TextField>
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              value={formData.password}
              onChange={onChangeField}
              required
              fullWidth={true}
              color="primary"
              size="small"
              type="password"
              name="password"
              label="password"
              variant={variant}
            ></TextField>
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              value={formData.passwordRepeat}
              onChange={onChangeField}
              error={
                formData.passwordRepeat !== formData.password &&
                formData.passwordRepeat.length > 0
              }
              helperText={
                formData.passwordRepeat !== formData.password &&
                formData.passwordRepeat.length > 0
                  ? "la password non corrisponde"
                  : ""
              }
              required
              fullWidth={true}
              color="primary"
              size="small"
              type="password"
              name="passwordRepeat"
              label="ripeti password"
              variant={variant}
            ></TextField>
          </Grid>
          <Grid item xs={12}>
            <Stack alignItems={"left"} justifyContent={"space-around"}>
              <Box>
                <Checkbox
                  key={1}
                  required={true}
                  name="privacy"
                  checked={formData.privacy}
                  onClick={(e) => {
                    setFormData({
                      ...formData,
                      privacy: !formData.privacy,
                    });
                    e.preventDefault();
                  }}
                  inputProps={{ "aria-label": "controlled" }}
                />
                <Typography
                  variant="body2"
                  component={"span"}
                  color={"#757575"}
                >
                  Ho letto e accettato espressamente la{" "}
                  <Link
                    to="/privacy-policy"
                    target="_blank"
                    style={{ color: "#757575" }}
                  >
                    Privacy Policy*
                  </Link>
                </Typography>
              </Box>
              <Box>
                <Checkbox
                  key={2}
                  name="eula"
                  checked={formData.eula}
                  onClick={(e) => {
                    if (!formData.eula) {
                      setOpen(true);
                      return;
                    }
                    setFormData({
                      ...formData,
                      eula: !formData.eula,
                    });
                    e.preventDefault();
                  }}
                  inputProps={{ "aria-label": "controlled" }}
                />
                <Typography
                  variant="body2"
                  component={"span"}
                  color={"#757575"}
                >
                  Ho letto e accettato i Termini e le Condizioni d'Uso*
                </Typography>
              </Box>
              <Box>
                <Checkbox
                  key={3}
                  name="promo"
                  checked={formData.promo}
                  onClick={(e) => {
                    setFormData({
                      ...formData,
                      promo: !formData.promo,
                    });
                    e.preventDefault();
                  }}
                  inputProps={{ "aria-label": "controlled" }}
                />
                <Typography
                  variant="body2"
                  component={"span"}
                  color={"#757575"}
                >
                  Inviatemi comunicazioni commerciali
                </Typography>
              </Box>
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Stack direction={"row"} alignItems={"center"}>
              <Button
                onClick={decreaseStep}
                size="large"
                variant="text"
                sx={{
                  color: "primary.main",
                  borderColor: "primary.main",
                  marginLeft: {
                    xs: "1%",
                  },
                  marginBottom: "2%",
                  marginRight: "auto",
                }}
              >
                {"INDETRO"}
              </Button>
              <Button
                disabled={!usernameAvaiable}
                onSubmit={submitHandler}
                type="submit"
                size="large"
                variant="outlined"
                sx={{
                  color: "primary.main",
                  borderColor: "primary.main",
                  marginRight: {
                    xs: "1%",
                  },
                  marginBottom: "2%",
                  marginLeft: "auto",
                }}
              >
                {"INVIA"}
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </form>
      <Modal
        open={open}
        onClose={closeModal}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box sx={{ ...style, width: 400, maxHeight: "70vh" }}>
          <Stack spacing={2} padding={1}>
            <Typography
              color={"primary.main"}
              id="parent-modal-title"
              sx={{
                fontSize: "1.2rem",
                fontWeight: 500,
              }}
            >
              Condizioni d'uso
            </Typography>
            <Divider />
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                verticalAlign: "middle",
              }}
            >
              <Typography
                variant="body1"
                id="parent-modal-description"
                sx={{
                  overflow: "scroll",
                  fontSize: "0.8rem",
                  fontWeight: 400,
                  color: "#626262",
                  wordWrap: "break-word",
                  maxHeight: "40vh",
                }}
              >
                {`Termini e Condizioni d’uso del software N.I.C.
                  per gli Utenti del Network Immobiliare Cerchiara
                  Gli Utenti che utilizzano il presente software, in quanto partecipanti al
                  network, dichiarano di conoscere e accettare le presenti condizioni generali
                  Titolare del software e dei relativi Servizi
                  Unico titolare del software N.I.C., nonché di tutti i relativi diritti inerenti e conseguenti all’uso e allo sfruttamento del medesimo è la società Cerchiara Immobil Group S.r.l.s.
                  (C.F./P. Iva 11129070964), con sede legale in Monza (MB), alla via Pavoni n. 10, in persona dell’Amministratore Unico e legale rappresentante Sig. Gaetano Cerchiata (C.F. CRC GTN 66R14 C489J), PEC cerchiaraimmobilgroup@pec.it, e-mail assitenza@nic-italia.com, di seguito anche solo “il Titolare”. Oggetto
                  Il software consente gratuitamente agli Utenti, in qualità di partecipanti al Network Immobiliare Cerchiara, di accedere ad un’area personale tramite un link di invito e di creare il proprio profilo comunicando i propri dati personali, di contatto e di pagamento, previa sottoscrizione dell’accordo di adesione al network. E ciò al fine di segnalare alla società Cerchiara Immobil Group S.r.l.s. potenziali clienti del servizio di intermediazione immobiliare, alle condizioni previste nell’accordo di adesione sottoscritto con la predetta società.
                  L’accesso all’area personale non comporta in carico agli Utenti del software alcun obbligo di tipo economico, trattandosi di uno strumento messo a disposizione del tutto gratuitamente dalla società Cerchiara Immobil Group S.r.l.s. per consentire il fruttuoso svolgimento della partecipazione degli Utenti al network; non potrà essere richiesto agli Utenti alcun tipo di contributo economico per l’uso del software, nemmeno in termini di rimborso spese.
                  Uso del software
                  I contenuti del software sono protetti dalle leggi internazionali sul copyright e da ogni altro diritto di proprietà intellettuale, di cui la società Cerchiara Immobil Group S.r.l.s. è titolare esclusivo. Gli Utenti non acquisiscono quindi alcun diritto sulla proprietà intellettuale del presente software, fatto salvo il temporaneo diritto di utilizzo limitato, non esclusivo, non trasferibile e non cedibile.
                  L’utilizzo del software, così come la partecipazione al network, è consentito esclusivamente ad Utenti maggiorenni secondo la legge italiana (maggiori di anni 18), e
                   dovrà avvenire secondo quanto stabilito nei presenti Termini e Condizioni del software, che in ogni caso dovrà essere utilizzato secondo le disposizioni di legge.
                  L’utilizzo del software presuppone l’esistenza di un valido rapporto contrattuale tra gli Utenti e la società Cerchiara Immobil Group S.r.l.s, nonché l’integrale accettazione della privacy policy (per informazioni sull’utilizzo dei dati personali, fare riferimento al seguente link assitenza@nic-italia.com) e dei presenti Termini e Condizioni.
                  Il software N.I.C. e tutte le funzionalità dello stesso vengono messi a disposizione degli Utenti ai presenti Termini e Condizioni, senza alcuna garanzia, esplicita o implicita, che non sia obbligatoria per legge.
                   Registrazione
                  Per poter utilizzare il software, gli Utenti devono iscriversi attraverso il link di invito ricevuto, fornendo in maniera veritiera e completa tutti i dati richiesti durante la procedura di registrazione, senza ricorrere all’utilizzo di indirizzi e-mail temporanei o alias.
                  Gli Utenti dichiarano sin d’ora di autorizzare il Titolare e le persone da lui eventualmente incaricate all’utilizzo dei predetti dati di registrazione, di contatto e di pagamento esclusivamente per le finalità previste dall’accordo di adesione al network, compreso l’invio agli Utenti di comunicazioni relative allo stato di evoluzione delle segnalazioni fornite e dei pagamenti loro spettanti.
                  Resta espressamente inteso che in caso di omessa, incompleta o errata compilazione dei dati richiesti nella sezione “impostazioni” del profilo da parte degli Utenti, il Titolare del software non sarà responsabile per eventuali ritardi o omissioni nell’esecuzione delle prestazioni a carico della società Cerchiara Immobil Group S.r.l.s.
                  Gli Utenti hanno l’onere di custodire e mantenere riservate le proprie credenziali di accesso al software, di cui possono modificare in ogni momento la password.
                  Resta inteso che in nessun caso il Titolare potrà essere ritenuto responsabile per smarrimento, diffusione, furto o utilizzo non consentito da parte di terzi, a qualsivoglia
                   titolo, delle credenziali di accesso degli Utenti.
                  Utilizzo non consentito
                  Il software dovrà essere utilizzato secondo quanto stabilito nei presenti Termini e Condizioni d’uso.
                  È fatto espresso divieto di:
                  - aggirare i sistemi informatici usati dal software N.I.C. o dai suoi licenziatari per
                  proteggere il contenuto accessibile tramite di esso;
                  
                   - copiare, conservare, modificare, cambiare, o alterare in qualunque modo il contenuto delle segnalazioni inserite tramite il software;
                  - utilizzare qualunque robot, spider, applicazione di ricerca e/o di reperimento di siti, ovvero qualunque altro dispositivo, processo o mezzo automatico per accedere, recuperare, effettuare scraping o indicizzare qualunque porzione del software o dei suoi contenuti;
                  - utilizzare il software in qualunque altra modalità impropria tale da violare questi Termini e Condizioni.
                  Eliminazione e chiusura dell’account degli Utenti
                   Gli Utenti registrati possono interrompere l’utilizzo del software in ogni momento e richiedere l’eliminazione dei propri account contattando direttamente il Titolare al seguente indirizzo e-mail: assitenza@nic-italia.com.
                  Il Titolare, in caso di violazione dei presenti Termini e Condizioni o di segnalazioni di utilizzo improprio del software, si riserva il diritto di chiudere l’account degli Utenti in ogni momento e senza preavviso, nonché di negarne l’utilizzo.
                  Responsabilità e doveri degli Utenti
                  Gli Utenti sono totalmente ed esclusivamente responsabili dell’uso del software e si impegnano a utilizzarlo conformemente alle norme di sicurezza e buonsenso.
                  Gli Utenti si impegnano inoltre ad utilizzare il form di segnalazione di potenziali clienti del servizio di intermediazione immobiliare al solo scopo di farli contattare dal Titolare o da suoi delegati per la verifica dell’interesse alla vendita e/o locazione di beni immobili. Gli Utenti si impegnano altresì a comunicare in maniera veritiera, completa e tempestiva ogni dato in loro possesso riguardante i potenziali clienti del servizio di intermediazione immobiliare fornito dalla società Cerchiara Immobil Group S.r.l.s. e/o degli immobili. Gli Utenti dichiarano di essere stati autorizzati dai potenziali clienti alla comunicazione tramite il software dei dati personali e di contatto degli stessi; pertanto, si assumono ogni
                   responsabilità in ordine alle eventuali contestazioni che dovessero emergere.
                  Gli Utenti si assumono ogni responsabilità per eventuali danni che possano derivare al proprio sistema informatico o a quello di terzi dall’uso del software.
                  Responsabilità e doveri del Titolare
                  Il Titolare si impegna a contattare tempestivamente i potenziali clienti segnalati dagli Utenti, al fine di verificare l’interesse alla vendita e/o locazione di beni immobili di loro proprietà e la fattibilità e/o convenienza degli affari segnalati.
                  
                   Il Titolare resta quindi l’unico legittimato alla valutazione in ordine alla validità della segnalazione e si impegna – in caso di valutazione negativa – a comunicare tempestivamente agli Utenti la decisione di non dar seguito alla segnalazione, senza necessità di motivare la propria determinazione.
                  Il Titolare si impegna inoltre ad aggiornare tempestivamente l’area personale degli Utenti, inserendo l’evoluzione delle segnalazioni effettuate, nonché il buon esito degli affari eventualmente conclusi e pertanto l’ammontare del compenso spettante agli Utenti in relazione alle provvigioni effettivamente incassate dalla società Cerchiara Immobil Group S.r.l.s.
                   Interruzione dell’utilizzo del software
                  Il Titolare si riserva il diritto di aggiungere, rimuovere funzionalità o caratteristiche ovvero modificare, sospendere o interrompere del tutto o in parte l’utilizzo del software da parte degli Utenti, sia in via temporanea che definitiva, in qualsiasi momento, anche senza preavviso e senza essere tenuto ad indicare le ragioni sottese alle predette azioni. Il Titolare non potrà essere ritenuto responsabile dei danni conseguenti alla mancata utilizzazione del software da parte degli Utenti a causa dell’errato o mancato funzionamento dei mezzi elettronici di comunicazione, per cause estranee alla sfera del proprio prevedibile controllo (malfunzionamenti di server, software, antivirus, nonché azioni di hacker o altri Utenti aventi accesso alla rete, etc.).
                  Modifiche ai presenti Termini e Condizioni
                  Il Titolare si riserva il diritto di apportare modifiche ai presenti Termini e Condizioni in qualunque momento, dandone avviso agli Utenti mediante l’invio di apposita comunicazione all’indirizzo mail comunicato all’interno dell’area personale degli stessi. Gli Utenti che continuino ad utilizzare il software successivamente alla comunicazione delle modifiche, accettano senza riserva i nuovi Termini e Condizioni.
                  Cessione del contratto
                   Il Titolare si riserva il diritto di trasferire, cedere o a qualunque titolo disporre tutti o alcuni dei diritti o obblighi derivanti dai presenti Termini e Condizioni, purché i diritti degli Utenti qui previsti non siano pregiudicati.
                  Comunicazioni
                  Tutte le comunicazioni relative al software devono essere inviate utilizzando le informazioni di contatto indicate nella sezione “Titolare”, in epigrafe.
                  Inefficacia e nullità parziale
                  
                  Qualora una qualsiasi clausola dei presenti Termini e Condizioni dovesse risultare nulla, non valida o inefficace, la suddetta clausola sarà eliminata mentre le restanti clausole non saranno da ciò condizionate e rimarranno pienamente efficaci.
                  Legge applicabile e Foro competente
                  I presenti Termini e Condizioni e tutte le controversie in merito ad esecuzione, interpretazione e validità del presente contratto sono soggette alla legge, alla giurisdizione dello Stato e alla competenza esclusiva del Foro di Milano, salvo quanto disposto da norme di legge non derogabili.
                  Aggiornamenti
                  I presenti Termini e Condizioni sono aggiornati alla data del 13 giugno 2023.`}
              </Typography>
            </Box>
            <Divider />
            <Stack direction={"row-reverse"} justifyItems={"flex-end"}>
              <Button
                size="small"
                sx={{ color: "white" }}
                variant="contained"
                onClick={acceptEula}
              >
                {"ACCETTA TUTTO"}
              </Button>
              <Button
                size="small"
                variant="outlined"
                onClick={closeModal}
                sx={{
                  marginRight: 2,
                }}
              >
                {"RIFIUTA"}
              </Button>
            </Stack>
          </Stack>
        </Box>
      </Modal>
    </>
  );
};

export default Step3;
