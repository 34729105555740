import React, { useState, useEffect } from "react";
import { Box, Container, Unstable_Grid2 as Grid } from "@mui/material";
import { useRecoilState, useRecoilValue } from "recoil";

import { BasicPageLayout } from "../../layouts/BasicPageLayout";
import { getRequestGenerator } from "../../helpers/httpHelper";
import { user, token, isAuth } from "../../athoms/auth";
import CardPieNewsChart from "../../components/CardPieNewsChart";
import CardMyNews from "../../components/CardMyNews";
import CardNewsForm from "../../components/CardNewsForm";

import TableUserNews from "../../components/TableUserNews";

const Notizie = () => {
  const [news, setNews] = useState({ myNews: 0, childrenNews: 0 });
  const userState = useRecoilValue(user);
  const tokenState = useRecoilValue(token);
  const [isAuthState, setIsAuthState] = useRecoilState(isAuth);

  useEffect(() => {
    if (isAuthState) {
      const getPersonAtomic = getRequestGenerator({
        url: `atomic/people/${userState.id}`,
        queryType: "GET",
        tokenState: tokenState,
        isAuthState: setIsAuthState,
      });
      const getNewsLog = getRequestGenerator({
        url: `atomic/personNewsLog`,
        queryType: "GET",
        tokenState: tokenState,
        filters: { commercialFlagExtId: userState.id },
        isAuthState: setIsAuthState,
      });
      Promise.all([getNewsLog(), getPersonAtomic()]).then(
        ([newsLog, person]) => {
          const myNews = Object.keys(newsLog.body.results).length;
          setNews({
            ...news,
            myNews: myNews,
            childrenNews: person.body.taggedNews - myNews,
          });
        }
      );
    } else {
      return;
    }
  }, [isAuthState]);

  return (
    <BasicPageLayout>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 8.5,
        }}
      >
        <Container maxWidth="xl">
          <Grid container spacing={4}>
            <Grid md={6} lg={6} xl={6} sm={12} xs={12}>
              <CardMyNews
                myNews={news.myNews}
                sx={{
                  height: "100%",
                  boxShadow:
                    "0px 5px 22px rgba(0, 0, 0, 0.05), 0px 0px 0px 0.5px rgba(0, 0, 0, 0.035)",
                  borderRadius: 3,
                }}
              />
            </Grid>
            <Grid md={6} lg={6} xl={6} sm={12} xs={12}>
              <CardMyNews
                childNews={news.childrenNews}
                sx={{
                  height: "100%",
                  boxShadow:
                    "0px 5px 22px rgba(0, 0, 0, 0.05), 0px 0px 0px 0.5px rgba(0, 0, 0, 0.035)",
                  borderRadius: 3,
                }}
              />
            </Grid>
            <Grid md={6} lg={6} xl={6} sm={12} xs={12}>
              <CardNewsForm
                user={userState}
                token={tokenState}
                setIsAuthState={setIsAuthState}
                setNews={setNews}
                news={news}
              />
            </Grid>
            <Grid md={6} lg={6} xl={6} sm={12} xs={12}>
              <CardPieNewsChart
                data={[news.myNews, news.childrenNews]}
                labels={["Mie Notizie", "Da segnalatori"]}
                sx={{
                  height: "100%",
                  boxShadow:
                    "0px 5px 22px rgba(0, 0, 0, 0.05), 0px 0px 0px 0.5px rgba(0, 0, 0, 0.035)",
                  borderRadius: 3,
                }}
              />
            </Grid>
            <Grid xs={12}>
              <TableUserNews />
            </Grid>
          </Grid>
        </Container>
      </Box>
    </BasicPageLayout>
  );
};

export default Notizie;
