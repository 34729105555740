import React, { useState, useEffect } from "react";
import {
  Paper,
  Stack,
  Container,
  Typography,
  Unstable_Grid2 as Grid,
  TextField,
  Button,
  InputAdornment,
  Box,
  Modal,
  Divider,
  TextareaAutosize,
} from "@mui/material";
import { postRequestGenerator } from "../../helpers/httpHelper";

const CardNewsForm = ({ user, token, setIsAuthState, setNews, news }) => {
  const [formData, setFormData] = useState(generateInitialState(user));
  const variant = "outlined";
  const [open, setOpen] = useState(false);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid",
    borderColor: "primary.main",
    boxShadow: 24,
    borderRadius: 3,
    pt: 2,
    px: 4,
    pb: 3,
  };

  const submitHandler = (e) => {
    e.preventDefault();
    setOpen(true);
  };

  const onChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
    e.preventDefault();
  };

  const closeModal = (e) => {
    setOpen(false);
    e.preventDefault();
  };

  const acceptEula = (e) => {
    const sendFormApi = postRequestGenerator({
      url: "aggregated/news",
      queryType: "POST",
      tokenState: token,
      body: generateBody(formData, user),
      isAuthState: setIsAuthState,
    });
    sendFormApi().then((_) => {
      setFormData(generateInitialState(user));
    });
    closeModal(e);
    e.preventDefault();
  };

  return (
    <>
      <Paper
        component={"div"}
        sx={{
          borderRadius: 3,
          textAlign: "outlined",
          // overflow: "auto",

          boxShadow:
            "0px 5px 22px rgba(0, 0, 0, 0.05), 0px 0px 0px 0.5px rgba(0, 0, 0, 0.035)",
          py: 0,
          px: 2,
          height: "100%",
        }}
      >
        <Stack spacing={0}>
          <Container sx={{ marginTop: ".95rem", textAlign: "left" }}>
            <Typography
              color="text.secondary"
              variant="overline"
              sx={{
                fontSize: ".95rem",
                color: "#424242",
              }}
            >
              nuova notizia
            </Typography>
          </Container>

          <form sx={{ textAlign: "center", p: 1 }} onSubmit={submitHandler}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField
                  value={formData.name}
                  onChange={onChange}
                  fullWidth={true}
                  color="primary"
                  size="small"
                  type="text"
                  name="name"
                  label="nome"
                  variant={variant}
                ></TextField>
              </Grid>
              <Grid item xs={6}>
                {" "}
                <TextField
                  value={formData.surname}
                  onChange={onChange}
                  fullWidth={true}
                  required
                  color="primary"
                  size="small"
                  type="text"
                  name="surname"
                  label="cognome"
                  variant={variant}
                ></TextField>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  value={formData.contact}
                  onChange={onChange}
                  fullWidth={true}
                  required
                  color="primary"
                  size="small"
                  type="text"
                  name="contact"
                  label="contatto"
                  variant={variant}
                ></TextField>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  value={formData.address}
                  onChange={onChange}
                  fullWidth={true}
                  color="primary"
                  size="small"
                  type="text"
                  name="address"
                  label="indirizzo"
                  variant={variant}
                ></TextField>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  value={formData.locality}
                  onChange={onChange}
                  fullWidth={true}
                  color="primary"
                  size="small"
                  type="text"
                  name="locality"
                  label="località"
                  variant={variant}
                ></TextField>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  value={formData.zip}
                  onChange={onChange}
                  fullWidth={true}
                  color="primary"
                  size="small"
                  type="text"
                  name="zip"
                  label="cap"
                  variant={variant}
                ></TextField>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  multiline
                  value={formData.note}
                  inputProps={{ maxLength: 180 }}
                  onChange={onChange}
                  fullWidth={true}
                  color="primary"
                  size="small"
                  type="text"
                  name="note"
                  label="note"
                  variant={variant}
                ></TextField>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  value={formData.requiredPrice}
                  onChange={onChange}
                  fullWidth={true}
                  color="primary"
                  size="small"
                  type="text"
                  name="requiredPrice"
                  label="prezzo richiesto"
                  variant={variant}
                  InputProps={{
                    inputMode: "numeric",
                    pattern: "[0-9]*",
                    endAdornment: (
                      <InputAdornment
                        position="end"
                        sx={{
                          color: "primary.main",
                        }}
                      >
                        <Typography>€</Typography>
                      </InputAdornment>
                    ),
                  }}
                ></TextField>
              </Grid>
              <Grid
                item
                xs={6}
                sx={{
                  position: "relative",
                }}
              >
                <Stack
                  direction={"row"}
                  justifyContent={"flex-end"}
                  alignItems={"baseline"}
                >
                  {/* <TextField size="small"> */}
                  <Button
                    onSubmit={submitHandler}
                    type="submit"
                    size="normal"
                    variant="contained"
                    sx={{
                      color: "white",
                      borderColor: "primary.main",
                      width: "40%",
                      position: "absolute",
                      top: "50%",
                      transform: "translateY(-50%)",
                    }}
                  >
                    {" "}
                    INVIA{" "}
                  </Button>
                </Stack>
                {/* </TextField> */}
              </Grid>
            </Grid>
          </form>
        </Stack>
      </Paper>
      <Modal
        open={open}
        onClose={closeModal}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box sx={{ ...style, width: 400, maxHeight: "70vh" }}>
          <Stack spacing={2} padding={1}>
            <Typography
              color={"primary.main"}
              id="parent-modal-title"
              sx={{
                fontSize: "1.2rem",
                fontWeight: 500,
              }}
            >
              Condizioni d'uso
            </Typography>
            <Divider />
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                verticalAlign: "middle",
              }}
            >
              <Typography
                variant="body1"
                id="parent-modal-description"
                sx={{
                  overflow: "scroll",
                  fontSize: "0.8rem",
                  fontWeight: 400,
                  color: "#626262",
                  wordWrap: "break-word",
                  maxHeight: "40vh",
                }}
              >
                {`Dichiaro sotto mia esclusiva responsabilità di aver comunicato in maniera veritiera e
completa ogni dato in mio possesso riguardante la presente segnalazione e di essere stato
autorizzato dal potenziale cliente alla comunicazione tramite il software dei suoi dati
personali e di contatto.`}
              </Typography>
            </Box>
            <Divider />
            <Stack direction={"row-reverse"} justifyItems={"flex-end"}>
              <Button
                size="small"
                sx={{ color: "white" }}
                variant="contained"
                onClick={acceptEula}
              >
                {"INVIA"}
              </Button>
              <Button
                size="small"
                variant="outlined"
                onClick={closeModal}
                sx={{
                  marginRight: 2,
                }}
              >
                {"ANNULLA"}
              </Button>
            </Stack>
          </Stack>
        </Box>
      </Modal>
    </>
  );
};

function generateInitialState(user) {
  return {
    name: "",
    surname: "",
    commercialFlagExtId: user.id,
    contact: "",
    contactType: 0,
    address: "",
    number: "",
    locality: "",
    zip: "",
    addressType: 2,
    houseType: 1,
    requiredPrice: "",
    note: "",
  };
}

function generateBody(formData, user) {
  return {
    toInsertInTree: false,
    newsStatus: 0,
    note: formData.note,
    people: {
      name:
        formData.name.trim().charAt(0).toUpperCase() + formData.name.slice(1),
      surname:
        formData.surname.trim().charAt(0).toUpperCase() +
        formData.surname.slice(1),
      commercialFlagExtId: user.id,
    },
    contacts: [
      {
        contact: formData.contact.trim(),
        contactType: formData.contactType,
      },
    ],
    addresses: [
      {
        address:
          formData.address.trim().charAt(0).toUpperCase() +
          formData.address.slice(1),
        number: formData.number,
        locality:
          formData.locality.trim().charAt(0).toUpperCase() +
          formData.locality.slice(1),
        zip: formData.zip.trim(),
        addressType: formData.addressType,
      },
    ],
    houses: {
      houseType: formData.houseType,
      requiredPrice: parseFloat(
        formData.requiredPrice.trim().replace(",", ".")
      ),
      note: formData.note,
    },
  };
}

export default CardNewsForm;
