import StarIcon from "@heroicons/react/24/solid/StarIcon";
import {
  Avatar,
  Card,
  CardContent,
  Stack,
  SvgIcon,
  Typography,
} from "@mui/material";

const CardPersonalLevel = (props) => {
  const { sx, personAggregate } = props;
  const level = personAggregate.personalLevel.name;
  const percentage = personAggregate.personalLevel.percentage;

  return (
    <Card sx={sx}>
      <CardContent>
        <Stack
          alignItems="flex-start"
          direction="row"
          justifyContent="space-between"
          spacing={3}
        >
          <Stack spacing={1}>
            <Typography color="text.secondary" variant="overline">
              CARRIERA
            </Typography>
            <Typography
              variant="body2"
              sx={{
                fontSize: ".95rem",
                lineHeight: 1.25,
                textTransform: "uppercase",
              }}
            >
              {level}
            </Typography>
          </Stack>
          <Avatar
            sx={{
              backgroundColor: "primary.main",
              // backgroundColor: "rgb(99,184,130)",
              height: 56,
              width: 56,
            }}
          >
            {/* <SvgIcon>
              <StarIcon />
            </SvgIcon> */}
            <Typography
              variant="body2"
              sx={{
                fontSize: "1.1rem",
                lineHeight: 1.35,
              }}
            >
              {" "}
              {`${percentage}%`}{" "}
            </Typography>
          </Avatar>
        </Stack>
      </CardContent>
    </Card>
  );
};

export default CardPersonalLevel;
