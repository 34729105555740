// import { Tree } from "react-tree-graph";
import React from "react";
import { Tree, TreeNode } from "react-organizational-chart";
import { ReactId } from "reactjs-id";
import { Typography, Paper, useTheme, CircularProgress } from "@mui/material";

const ChildrenGraph = ({ userFromState, apiResponse }) => {
  const theme = useTheme();
  if (
    userFromState == null ||
    apiResponse == null ||
    apiResponse.length === 0 ||
    Object.keys(userFromState).length === 0
  ) {
    return (
      <Paper
        component={"div"}
        sx={{
          borderRadius: 3,
          textAlign: "center",
          overflow: "auto",
          maxWidth: "100%",
          margin: "0 auto",
          maxHeight: "100%",
          boxShadow:
            "0px 5px 22px rgba(0, 0, 0, 0.05), 0px 0px 0px 0.5px rgba(0, 0, 0, 0.035)",
          py: 8,
          px: 2,
        }}
      >
        <CircularProgress />
      </Paper>
    );
  }
  const tree = processData(apiResponse, userFromState);

  return (
    <Paper
      component={"div"}
      sx={{
        borderRadius: 3,
        textAlign: "center",
        overflow: "auto",
        maxWidth: "100%",
        margin: "0 auto",
        maxHeight: "100%",
        boxShadow:
          "0px 5px 22px rgba(0, 0, 0, 0.05), 0px 0px 0px 0.5px rgba(0, 0, 0, 0.035)",
        py: 8,
        px: 2,
      }}
    >
      <Tree
        lineHeight="1.3rem"
        lineBorderRadius="10px"
        lineWidth=".19rem"
        lineColor={theme.palette.primary.main}
        style={{ margin: "0 auto" }}
        label={
          <Typography fontFamily={theme.typography.fontFamily} variant="h6">
            {tree.label.toUpperCase()}
          </Typography>
        }
      >
        {tree.children.map((e) => recursiveRendering(e))}
      </Tree>
    </Paper>
  );
};

export default ChildrenGraph;

function processData(apiResponse, userFromState) {
  const newArray = [];
  const computedChildrenArray = [];
  if (Object.keys(newArray).length === 0) {
    userFromState = {
      ...userFromState,
      personId: userFromState.id,
      children: [],
      name: `${userFromState.visual}`,
      label: `${userFromState.visual}`,
    };
    newArray.push(userFromState);
    newArray.push(
      ...apiResponse.map((e) => {
        return {
          ...e,
          children: [],
        };
      })
    );
  }
  for (const item of newArray) {
    item.label = `${item.name} lv.${item.personalLevelExtId - 1}`;
    item.children = newArray.filter(
      (e) => item.personId === e.commercialFlagExtId
    );
    computedChildrenArray.push({ ...item });
  }

  return computedChildrenArray[0];
}

function recursiveRendering(tree) {
  if (tree.children.length === 0) {
    return (
      <TreeNode
        key={ReactId()}
        label={
          <Typography
            variant="body2"
            color={tree.personalLevelExtId === 1 ? "child.main" : "#e6492f"}
          >
            {tree.label.toUpperCase()}
          </Typography>
        }
      ></TreeNode>
    );
  } else {
    return (
      <TreeNode
        key={ReactId()}
        label={
          <Typography
            color={tree.personalLevelExtId === 1 ? "child.main" : "#e6492f"}
            variant="body2"
          >
            {tree.label.toUpperCase()}
          </Typography>
        }
      >
        {tree.children.map((e) => recursiveRendering(e))}
      </TreeNode>
    );
  }
}
