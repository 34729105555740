import React, { useEffect, useState } from "react";
import {
  Box,
  Container,
  Unstable_Grid2 as Grid,
  Stack,
  Button,
} from "@mui/material";
import { useRecoilState, useRecoilValue } from "recoil";

import { BasicPageLayout } from "../../../layouts/BasicPageLayout";
import CardPersonDetails from "../../../components/CardPersonDetails";
import CardUserDetails from "../../../components/CardUserDetails";
import CardAddressDetails from "../../../components/CardAddressDetails";
import CardContactsDetails from "../../../components/CardContactsDetails";

import { postRequestGenerator } from "../../../helpers/httpHelper";
import { token, isAuth } from "../../../athoms/auth";
import { useLocation } from "react-router-dom";
import { newsStatus } from "../../../helpers/enums";
import CardHouseAddress from "../../../components/CardHouseAddress";
import CardRequiredPriceDetails from "../../../components/CardRequiredPriceDetails";

const GestioneNotizieEdit = () => {
  const tokenState = useRecoilValue(token);
  const [isAuthState, setIsAuthState] = useRecoilState(isAuth);
  const newsData = useLocation().state;
  const [isModified, setIsModified] = useState(false);
  const userState = newsData.owner;

  useEffect(() => {
    if (isModified) {
      const setNewsAsVerified = postRequestGenerator({
        url: `atomic/news/${newsData.news.id}`,
        queryType: "PATCH",
        tokenState: tokenState,
        isAuthState: setIsAuthState,
        body: { newsStatus: newsStatus.indexOf("Verificata") },
      });

      const insertInTree = postRequestGenerator({
        url: `aggregated/news`,
        queryType: "POST",
        tokenState: tokenState,
        isAuthState: setIsAuthState,
        body: generateBody(newsData),
      });
      setNewsAsVerified();
      insertInTree();
    }
  }, [isModified]);

  generateBody(newsData);

  return (
    <BasicPageLayout>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 8.5,
        }}
      >
        <Container maxWidth="xl">
          <Stack justifyContent={"center"} alignItems={"center"}>
            <Grid container spacing={3}>
              <Grid xs={12} md={6} lg={6}>
                <CardHouseAddress
                  addressData={newsData.address}
                  userState={userState}
                  tokenState={tokenState}
                  setIsAuthState={setIsAuthState}
                  setIsModified={setIsModified}
                />
              </Grid>
              <Grid xs={12} md={6} lg={6}>
                <CardRequiredPriceDetails
                  houseData={newsData.house}
                  userState={userState}
                  tokenState={tokenState}
                  setIsAuthState={setIsAuthState}
                  setIsModified={setIsModified}
                />
              </Grid>
            </Grid>
          </Stack>
        </Container>
      </Box>
    </BasicPageLayout>
  );
};

const generateBody = (newsData) => {
  return {
    ...newsData.news,
    addresses: { ...newsData.address, addressType: 0 },
    contacts: [{ ...newsData.ownerContact }],
    toInsertInTree: true,
    people: { ...newsData.owner },
    houses: { ...newsData.news },
    personJobs: [],
  };
};

export default GestioneNotizieEdit;
