const ThemeOptions = {
  palette: {
    mode: "light",
    primary: {
      main: "#B1A58F",
    },
    secondary: {
      main: "#678dd6",
    },
    text: {
      primary: "rgba(0,0,0,0.87)",
    },
    error: {
      main: "#e6492f",
    },
    warning: {
      main: "#ffad3e",
    },
    info: {
      main: "#f5c505",
    },
    success: {
      main: "#20b726",
    },
    news: { main: "#ffac33" },
    child: { main: "#678dd6" },
    childrenInProduction: { main: "#e6492f" },
    divider: "rgba(121,116,116,0.12)",
  },
  typography: {
    fontFamily: ["Roboto", '"Helvetica Neue"', "Arial", "sans-serif"].join(","),
  },
};

export default ThemeOptions;
