import ky from "ky";
import secureLocalStorage from "react-secure-storage";

import baseUrl from "./config";
import { encrypt, decrypt } from "./cryptoHelper";

const newHttpHeader = (queryType, tokenState, body) => {
  return {
    header: {
      token: tokenState,
      queryType: queryType,
      errorCode: "00",
      errorMessage: "Success",
    },
    body: body,
  };
};

const newStandardHttpBody = (page, pageSize, query) => {
  return {
    query: query ?? "",
    filters: {}, //HO MODIFICATO QUESTO
  };
};

const newHttpBodyWithAndFilters = (filters) => {
  return {
    filters: {
      and: { ...filters },
    },
  };
};

const newHttpRequestGet = (
  queryType,
  tokenState,
  page,
  pageSize,
  filters = {}
) => {
  if (Object.keys(filters).length === 0) {
    return newHttpHeader(
      queryType,
      tokenState,
      newStandardHttpBody(page, pageSize)
    );
  } else {
    return newHttpHeader(
      queryType,
      tokenState,
      newHttpBodyWithAndFilters(filters)
    );
  }
};

const newHttpRequestPost = (queryType, tokenState, body) => {
  return newHttpHeader(queryType, tokenState, body);
};

const apiClient = ky.create({
  prefixUrl: baseUrl, // <- ENV variable
  //prefixUrl: "",
});

const { post } = apiClient;

const getRequestGenerator = ({
  url,
  queryType,
  tokenState,
  page,
  pageSize,
  filters,
  isAuthState,
} = {}) => {
  const plainRequest = newHttpRequestGet(
    queryType,
    tokenState,
    page,
    pageSize,
    filters
  );

  return async () => {
    // const encryptedResponse = await post(url, { body: encrypt(plainRequest) });
    // return decrypt(await encryptedResponse.text());
    //--PLAIN--
    // const encryptedResponse = await post(url, {
    //   body: JSON.stringify(plainRequest),
    // });
    // const res = JSON.parse(await encryptedResponse.text());
    // --ENCRYPTED--
    const encryptedResponse = await post(url, { body: encrypt(plainRequest) });
    const res = decrypt(await encryptedResponse.text());
    if (res.header.errorCode === "401") {
      secureLocalStorage.clear();
      isAuthState(false);
    }
    if (res.header.errorCode === "403") {
      alert("Operazione non permessa");
    }
    if (res.header.errorCode !== "00" && res.header.errorCode !== "403") {
      throw new Error(
        `ERRORE! Codice: ${res.header.errorCode} Messaggio: ${res.header.errorMessage}`
      );
    }
    return res;
  };
};

const postRequestGenerator = ({
  url,
  queryType,
  tokenState,
  body,
  isAuthState,
} = {}) => {
  const plainRequest = newHttpRequestPost(queryType, tokenState, body);
  return async () => {
    // const encryptedResponse = await post(url, { body: encrypt(plainRequest) });
    // return decrypt(await encryptedResponse.text());
    //--PLAIN--
    // const encryptedResponse = await post(url, {
    //   body: JSON.stringify(plainRequest),
    // });
    // const res = JSON.parse(await encryptedResponse.text());
    // --ENCRYPTED--
    const encryptedResponse = await post(url, { body: encrypt(plainRequest) });
    const res = decrypt(await encryptedResponse.text());
    if (res.header.errorCode === "401") {
      secureLocalStorage.clear();
      isAuthState(false);
    }
    if (res.header.errorCode === "403") {
      alert("Operazione non permessa");
    }
    if (res.header.errorCode !== "00" && res.header.errorCode !== "403") {
      throw new Error(`${res.header.errorCode} - ${res.header.errorMessage}`);
    }
    return res;
  };
};

export { getRequestGenerator, postRequestGenerator };
