const jobType = [
  "Dipendente",
  "Agente",
  "Agenzia",
  "Segnalatore",
  "Cliente",
  "Notaio",
  "Nessuno",
];

const newsStatus = [
  "Nuova",
  "Verificata",
  "Assegnata",
  "Valutata",
  "Mandato Ottenuto",
  "Proposta Fatta",
  "Proposta Accettata",
  "Compromesso Fatto",
  "Rogito Fatto",
  "Storico",
];

export { jobType, newsStatus };
