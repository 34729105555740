import { Container, SvgIcon } from "@mui/material";
import React from "react";

import PencilSquareIcon from "@heroicons/react/24/outline/PencilSquareIcon";

const TableGeneratePwdAction = () => {
  return (
    <SvgIcon
      color="primary"
      sx={{
        width: "1.35rem",
      }}
    >
      <PencilSquareIcon />
    </SvgIcon>
  );
};

export default TableGeneratePwdAction;
