import React, { useEffect, useMemo, useState } from "react";
import {
  DataGrid,
  itIT,
  GridToolbar,
  GridActionsCellItem,
} from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import { FormattedDate, FormattedNumber } from "react-intl";
import { newsStatus } from "../../helpers/enums";

import { postRequestGenerator } from "../../helpers/httpHelper";

const TableUserInsertedNews = ({
  newsData,
  setNewsData,
  tokenState,
  isAuthState,
  setIsAuthState,
  newsStatusState,
}) => {
  const [paginationState, setPaginationState] = useState(10);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const isVerified = newsStatus.indexOf("Verificata") === newsStatusState;

  const recordsFromApi = newsData.map((e) => {
    return {
      completeObject: e,
      newsId: e.news.id,
      registrationDate: e.house.registrationDate,
      commercialFlag: e.commercialFlag.visual,
      owner: e.owner.visual,
      ownerContact: e.ownerContact.contact,
      requiredPrice: e.house.requiredPrice,
      houseAddress: e.address.visual,
    };
  });

  const columns = useMemo(
    () => [
      {
        field: "newsId",
        headerName: "ID",
        width: 66.8,
      },
      {
        field: "registrationDate",
        headerName: "DATA",
        renderCell: (e) => <FormattedDate value={e.value} />,
        width: 80,
      },
      // {
      //   field: "commercialFlag",
      //   headerName: "SEGNALATORE",
      //   width: 190,
      // },
      {
        field: "owner",
        headerName: "PROPRIETARIO",
        width: 190,
      },
      {
        field: "ownerContact",
        headerName: "CONTATTO",
        width: 140,
      },
      {
        field: "requiredPrice",
        headerName: "RICHIESTA",
        width: 95,
        renderCell: (e) => (
          <>
            <FormattedNumber value={e.value} />
            {" €"}
          </>
        ),
      },
      {
        field: "houseAddress",
        headerName: "INDIRIZZO",
        width: 420,
        maxWidth: 480,
      },
    ],
    [newsData]
  );

  return (
    <>
      <DataGrid
        loading={loading}
        showCellVerticalBorder={false}
        localeText={itIT.components.MuiDataGrid.defaultProps.localeText}
        columns={columns}
        rows={recordsFromApi}
        getRowId={(row) => row.newsId}
        initialState={{
          ...recordsFromApi,
          pagination: {
            paginationModel: { pageSize: paginationState, page: 0 },
          },
        }}
        pageSizeOptions={[5, 10, 20, 50]}
        sx={{
          boxShadow:
            "0px 5px 22px rgba(0, 0, 0, 0.05), 0px 0px 0px 0.5px rgba(0, 0, 0, 0.035)",
          borderRadius: 3,
          border: 0,
        }}
        slots={{
          toolbar: () => <GridToolbar sx={{ mb: 2 }} />,
        }}
      />
    </>
  );
};

export default TableUserInsertedNews;
