import React from "react";

import GradientBackground from "../../components/GradientBackround";
import CardPrivacyPolicy from "../../components/CardPrivacyPolicy";

const PrivacyPolicy = () => {
  return (
    <GradientBackground>
      <CardPrivacyPolicy />
    </GradientBackground>
  );
};

export default PrivacyPolicy;
