import React, { useState, useEffect } from "react";
import {
  Box,
  Container,
  Unstable_Grid2 as Grid,
  Stack,
  useTheme,
} from "@mui/material";
import { useRecoilState, useRecoilValue } from "recoil";

import { BasicPageLayout } from "../../layouts/BasicPageLayout";
import CardPersonDetails from "../../components/CardPersonDetails";
import CardUserDetails from "../../components/CardUserDetails";
import CardAddressDetails from "../../components/CardAddressDetails";
import CardContactsDetails from "../../components/CardContactsDetails";

import { getRequestGenerator } from "../../helpers/httpHelper";
import { user, token, isAuth } from "../../athoms/auth";

const ImpostazioniPage = () => {
  const [personAggregate, setPersonAggregate] = useState({
    people: {},
    contacts: [],
    personalLevel: {},
    personJobs: [],
    addresses: [],
    personalLevelRules: [],
  });

  const userState = useRecoilValue(user);
  const tokenState = useRecoilValue(token);
  const [isAuthState, setIsAuthState] = useRecoilState(isAuth);
  // const theme = useTheme();

  useEffect(() => {
    if (isAuthState) {
      const getPersonAggregate = getRequestGenerator({
        url: `aggregated/people/${userState.id}`,
        queryType: "GET",
        tokenState: tokenState,
        isAuthState: setIsAuthState,
      });
      getPersonAggregate().then((e) => {
        setPersonAggregate({
          ...e.body.results[0],
        });
      });
    } else {
      return;
    }
  }, [isAuthState]);

  return (
    <BasicPageLayout>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 8.5,
        }}
      >
        <Container maxWidth="xl">
          <Grid container spacing={3}>
            <Grid xs={12} md={6} lg={6}>
              <CardPersonDetails
                personData={personAggregate.people}
                userState={userState}
                tokenState={tokenState}
                setIsAuthState={setIsAuthState}
              />
            </Grid>
            <Grid xs={12} md={6} lg={6}>
              <CardUserDetails
                userState={userState}
                tokenState={tokenState}
                setIsAuthState={setIsAuthState}
              />
            </Grid>
            <Grid xs={12} md={6} lg={6}>
              <CardAddressDetails
                personType={personAggregate.people.personType}
                addressData={
                  personAggregate.addresses.filter(
                    (e) => e.addressType === 0 || e.addressType === 2
                  )[0]
                }
                userState={userState}
                tokenState={tokenState}
                setIsAuthState={setIsAuthState}
              />
            </Grid>
            <Grid xs={12} md={6} lg={6}>
              <CardContactsDetails
                email={
                  personAggregate.contacts.filter((e) => e.contactType === 2)[0]
                }
                telephone={
                  personAggregate.contacts.filter(
                    (e) => e.contactType === 0 || e.contactType === 1
                  )[0]
                }
                userState={userState}
                tokenState={tokenState}
                setIsAuthState={setIsAuthState}
              />
            </Grid>
          </Grid>
        </Container>
      </Box>
    </BasicPageLayout>
  );
};

export default ImpostazioniPage;
