import React, { useState, useEffect } from "react";
import {
  Box,
  Container,
  Unstable_Grid2 as Grid,
  Stack,
  useTheme,
} from "@mui/material";
import { useRecoilState, useRecoilValue } from "recoil";

import { BasicPageLayout } from "../../layouts/BasicPageLayout";
import CardChildren from "../../components/CardChildren";
import CardNews from "../../components/CardNews";
import CardPersonalLevel from "../../components/CardPersonalLevel";
import CardNextLevel from "../../components/CardNextLevel";
import CardChildrenInProduction from "../../components/CardChildrenInProduction";
import CardPieChartLevel from "../../components/CardPieChartLevel";

import { getRequestGenerator } from "../../helpers/httpHelper";
import { user, token, isAuth } from "../../athoms/auth";
import secureLocalStorage from "react-secure-storage";
import CardInsertNews from "../../components/CardInsertNews";
import CardInsertChild from "../../components/CardInsertChild";

const DashboardPage = () => {
  const [personAggregate, setPersonAggregate] = useState({
    people: {},
    contacts: [],
    personalLevel: {},
    personJobs: [],
    addresses: [],
    personalLevelRules: [],
  });
  const userState = useRecoilValue(user);
  const tokenState = useRecoilValue(token);
  const [isAuthState, setIsAuthState] = useRecoilState(isAuth);
  const theme = useTheme();

  useEffect(() => {
    if (isAuthState) {
      const getPersonAggregate = getRequestGenerator({
        url: `aggregated/people/${userState.id}`,
        queryType: "GET",
        tokenState: tokenState,
        isAuthState: setIsAuthState,
      });
      getPersonAggregate().then((e) => {
        setPersonAggregate({
          ...e.body.results[0],
          personalLevelRules: secureLocalStorage.getItem("personalLevelRules"),
        });
      });
    } else {
      return;
    }
  }, [isAuthState]);

  return (
    <BasicPageLayout>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 8.5,
        }}
      >
        <Container maxWidth="xl">
          <Grid container spacing={3}>
            <Grid xs={12} sm={6} lg={3}>
              <CardPersonalLevel
                personAggregate={personAggregate}
                sx={{
                  height: "100%",
                  boxShadow:
                    "0px 5px 22px rgba(0, 0, 0, 0.05), 0px 0px 0px 0.5px rgba(0, 0, 0, 0.035)",
                  borderRadius: 3,
                }}
              />
            </Grid>
            <Grid xs={12} sm={6} lg={3}>
              <CardNews
                personAggregate={personAggregate}
                sx={{
                  height: "100%",
                  boxShadow:
                    "0px 5px 22px rgba(0, 0, 0, 0.05), 0px 0px 0px 0.5px rgba(0, 0, 0, 0.035)",
                  borderRadius: 3,
                }}
              />
            </Grid>
            <Grid xs={12} sm={6} lg={3}>
              <CardChildren
                personAggregate={personAggregate}
                sx={{
                  height: "100%",
                  boxShadow:
                    "0px 5px 22px rgba(0, 0, 0, 0.05), 0px 0px 0px 0.5px rgba(0, 0, 0, 0.035)",
                  borderRadius: 3,
                }}
              />
            </Grid>
            <Grid xs={12} sm={6} lg={3}>
              <CardChildrenInProduction
                dashboard={true}
                personAggregate={personAggregate}
                sx={{
                  height: "100%",
                  boxShadow:
                    "0px 5px 22px rgba(0, 0, 0, 0.05), 0px 0px 0px 0.5px rgba(0, 0, 0, 0.035)",
                  borderRadius: 3,
                }}
              />

              {/* <OverviewTasksProgress sx={{ height: "100%" }} value={75.5} /> */}
            </Grid>

            <Grid xs={12} lg={7}>
              <Box sx={{ height: "100%", margin: 0 }}>
                <Stack spacing={3} direction={"column"}>
                  <CardNextLevel
                    personAggregate={personAggregate}
                    sx={{
                      height: "100%",
                      boxShadow:
                        "0px 5px 22px rgba(0, 0, 0, 0.05), 0px 0px 0px 0.5px rgba(0, 0, 0, 0.035)",
                      borderRadius: 3,
                    }}
                  />
                  <Stack
                    spacing={3}
                    direction={"row"}
                    justifyContent="space-between"
                  >
                    <CardInsertNews
                      sx={{
                        height: "100%",
                        boxShadow:
                          "0px 5px 22px rgba(0, 0, 0, 0.05), 0px 0px 0px 0.5px rgba(0, 0, 0, 0.035)",
                        borderRadius: 3,
                        width: "50%",
                      }}
                    />

                    <CardInsertChild
                      personAggregate={personAggregate}
                      sx={{
                        height: "100%",
                        boxShadow:
                          "0px 5px 22px rgba(0, 0, 0, 0.05), 0px 0px 0px 0.5px rgba(0, 0, 0, 0.035)",
                        borderRadius: 3,
                        width: "50%",
                      }}
                    />
                  </Stack>
                </Stack>
              </Box>
            </Grid>
            <Grid xs={12} lg={5}>
              <Box sx={{ height: "100%", margin: 0 }}>
                <CardPieChartLevel
                  personAggregate={personAggregate}
                  labels={["Notizie", "Segnalatori", "Segnalatori Lv.1"]}
                  sx={{
                    height: "100%",
                    [theme.breakpoints.up("xl")]: {
                      height: "97%",
                    },
                    boxShadow:
                      "0px 5px 22px rgba(0, 0, 0, 0.05), 0px 0px 0px 0.5px rgba(0, 0, 0, 0.035)",
                    borderRadius: 3,
                  }}
                />
              </Box>
            </Grid>

            <Grid xs={12} md={6} lg={4}>
              {/* <OverviewLatestProducts
                products={[
                  {
                    id: "5ece2c077e39da27658aa8a9",
                    image: "/assets/products/product-1.png",
                    name: "Healthcare Erbology",
                    updatedAt: subHours(now, 6).getTime(),
                  },
                  {
                    id: "5ece2c0d16f70bff2cf86cd8",
                    image: "/assets/products/product-2.png",
                    name: "Makeup Lancome Rouge",
                    updatedAt: subDays(subHours(now, 8), 2).getTime(),
                  },
                  {
                    id: "b393ce1b09c1254c3a92c827",
                    image: "/assets/products/product-5.png",
                    name: "Skincare Soja CO",
                    updatedAt: subDays(subHours(now, 1), 1).getTime(),
                  },
                  {
                    id: "a6ede15670da63f49f752c89",
                    image: "/assets/products/product-6.png",
                    name: "Makeup Lipstick",
                    updatedAt: subDays(subHours(now, 3), 3).getTime(),
                  },
                  {
                    id: "bcad5524fe3a2f8f8620ceda",
                    image: "/assets/products/product-7.png",
                    name: "Healthcare Ritual",
                    updatedAt: subDays(subHours(now, 5), 6).getTime(),
                  },
                ]}
                sx={{ height: "100%" }}
              /> */}
            </Grid>
            <Grid xs={12} md={12} lg={8}>
              {/* <OverviewLatestOrders
                orders={[
                  {
                    id: "f69f88012978187a6c12897f",
                    ref: "DEV1049",
                    amount: 30.5,
                    customer: {
                      name: "Ekaterina Tankova",
                    },
                    createdAt: 1555016400000,
                    status: "pending",
                  },
                  {
                    id: "9eaa1c7dd4433f413c308ce2",
                    ref: "DEV1048",
                    amount: 25.1,
                    customer: {
                      name: "Cao Yu",
                    },
                    createdAt: 1555016400000,
                    status: "delivered",
                  },
                  {
                    id: "01a5230c811bd04996ce7c13",
                    ref: "DEV1047",
                    amount: 10.99,
                    customer: {
                      name: "Alexa Richardson",
                    },
                    createdAt: 1554930000000,
                    status: "refunded",
                  },
                  {
                    id: "1f4e1bd0a87cea23cdb83d18",
                    ref: "DEV1046",
                    amount: 96.43,
                    customer: {
                      name: "Anje Keizer",
                    },
                    createdAt: 1554757200000,
                    status: "pending",
                  },
                  {
                    id: "9f974f239d29ede969367103",
                    ref: "DEV1045",
                    amount: 32.54,
                    customer: {
                      name: "Clarke Gillebert",
                    },
                    createdAt: 1554670800000,
                    status: "delivered",
                  },
                  {
                    id: "ffc83c1560ec2f66a1c05596",
                    ref: "DEV1044",
                    amount: 16.76,
                    customer: {
                      name: "Adam Denisov",
                    },
                    createdAt: 1554670800000,
                    status: "delivered",
                  },
                ]}
                sx={{ height: "100%" }}
              /> */}
            </Grid>
          </Grid>
        </Container>
      </Box>
    </BasicPageLayout>
  );
};

export default DashboardPage;
