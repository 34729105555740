import React from "react";
import {
  Box,
  Typography,
  Stack,
  Container,
  CardContent,
  Unstable_Grid2 as Grid,
} from "@mui/material";

import { FormattedDate } from "react-intl";

const ContentNotInvoiceable = ({ nextStartDate, nextEndDate }) => {
  return (
    <CardContent>
      <Stack
        direction={"row"}
        justifyContent={"space-around"}
        alignItems={"center"}
        spacing={2}
        padding={2}
      >
        <Stack spacing={1} alignItems={"center"} justifyContent={"center"}>
          <Typography
            variant="body2"
            sx={{
              fontSize: "1.05rem",
              color: "primary.main",
              fontWeight: 600,
            }}
          >
            {" "}
            IMPORTO NON ANCORA FATTURABILE
          </Typography>
          <Typography
            variant="body2"
            sx={{
              fontSize: ".8rem",
              color: "#757575",
            }}
          >
            Periodo di fatturazione dal{" "}
            <strong>
              <FormattedDate value={nextStartDate} />
            </strong>{" "}
            al
            <strong>
              {" "}
              <FormattedDate value={nextEndDate} />
            </strong>
          </Typography>
        </Stack>
      </Stack>
    </CardContent>
  );
};

export default ContentNotInvoiceable;
