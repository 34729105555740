import ArrowUpIcon from "@heroicons/react/24/solid/ArrowUpIcon";
import CheckBadgeIcon from "@heroicons/react/24/solid/CheckBadgeIcon";
import UsersIcon from "@heroicons/react/24/solid/UsersIcon";
import HomeModernIcon from "@heroicons/react/24/solid/HomeModernIcon";
import {
  Box,
  Avatar,
  Card,
  CardContent,
  Stack,
  SvgIcon,
  LinearProgress,
  Typography,
  useTheme,
} from "@mui/material";

const CardNextLevel = (props) => {
  const { sx, personAggregate } = props;
  const barWidth = { lg: "20rem", xs: "15rem", sm: "25rem", md: "40rem" };

  let nextLevel =
    personAggregate.personalLevelRules.filter(
      (e) => e.id === personAggregate.personalLevel.id + 1
    )[0] ?? personAggregate.personalLevel;
  const topLevelReached = nextLevel.id === personAggregate.personalLevel.id;

  return (
    <Card sx={sx}>
      <CardContent>
        <Stack
          alignItems="flex-start"
          direction="row"
          justifyContent="space-between"
          spacing={3}
        >
          <Stack spacing={1}>
            <Typography color="text.secondary" variant="overline">
              {topLevelReached
                ? "MASSIMO LIVELLO RAGGIUNTO"
                : `PROSSIMO LIVELLO`}
            </Typography>
            <Stack>
              <Stack
                sx={{ mt: 0.2 }}
                direction={"row"}
                alignItems={"center"}
                justifyContent={"space-between"}
              >
                <Avatar
                  sx={{
                    backgroundColor: "transparent",
                    color: "news.main",
                    height: 26,
                    width: 26,
                  }}
                >
                  <SvgIcon sx={{ height: "1rem" }}>
                    <HomeModernIcon />
                  </SvgIcon>
                </Avatar>
                <LinearProgress
                  value={
                    personAggregate.people.taggedNews >= nextLevel.requiredNews
                      ? 100
                      : (personAggregate.people.taggedNews /
                          nextLevel.requiredNews) *
                        100
                  }
                  variant="determinate"
                  color="news"
                  sx={{
                    backgroundColor: "#eeeeee",
                    height: 10,
                    borderRadius: 3,
                    width: barWidth,
                  }}
                />
                <Typography
                  color="text.secondary"
                  variant="body2"
                  sx={{ fontSize: ".68rem", marginLeft: 1 }}
                >
                  <strong>{`${personAggregate.people.taggedNews}/${nextLevel.requiredNews}`}</strong>
                </Typography>
              </Stack>

              <Stack
                sx={{ mt: 1.3 }}
                direction={"row"}
                alignItems={"center"}
                justifyContent={"space-between"}
              >
                <Avatar
                  sx={{
                    backgroundColor: "transparent",
                    color: "child.main",
                    height: 26,
                    width: 26,
                  }}
                >
                  <SvgIcon sx={{ height: "1rem" }}>
                    <UsersIcon />
                  </SvgIcon>
                </Avatar>
                <LinearProgress
                  value={
                    personAggregate.people.taggedPeople >=
                    nextLevel.requiredPeople
                      ? 100
                      : (personAggregate.people.taggedPeople /
                          nextLevel.requiredPeople) *
                        100
                  }
                  variant="determinate"
                  color="child"
                  sx={{
                    backgroundColor: "#eeeeee",
                    height: 10,
                    borderRadius: 3,
                    width: barWidth,
                  }}
                />

                <Typography
                  color="text.secondary"
                  variant="body2"
                  sx={{ fontSize: ".68rem", marginLeft: 1 }}
                >
                  <strong>{`${personAggregate.people.taggedPeople}/${nextLevel.requiredPeople}`}</strong>
                </Typography>
              </Stack>
              <Stack
                sx={{ mt: 1.3 }}
                direction={"row"}
                alignItems={"center"}
                justifyContent={"space-between"}
              >
                <Avatar
                  sx={{
                    backgroundColor: "transparent",
                    color: "childrenInProduction.main",
                    height: 26,
                    width: 26,
                  }}
                >
                  <SvgIcon sx={{ height: "1rem" }}>
                    <UsersIcon />
                  </SvgIcon>
                </Avatar>
                <LinearProgress
                  value={
                    personAggregate.people.peopleProduction >=
                    nextLevel.requiredPeopleFirstLevel
                      ? 100
                      : (personAggregate.people.peopleProduction /
                          nextLevel.requiredPeopleFirstLevel) *
                        100
                  }
                  variant="determinate"
                  color="childrenInProduction"
                  sx={{
                    backgroundColor: "#eeeeee",
                    height: 10,
                    borderRadius: 3,
                    width: barWidth,
                  }}
                />

                <Typography
                  color="text.secondary"
                  variant="body2"
                  sx={{ fontSize: ".68rem", marginLeft: 1 }}
                >
                  <strong>{`${personAggregate.people.peopleProduction}/${nextLevel.requiredPeopleFirstLevel}`}</strong>
                </Typography>
              </Stack>
            </Stack>
          </Stack>
          <Avatar
            sx={{
              backgroundColor: "rgb(99,184,130)",
              height: 56,
              width: 56,
            }}
          >
            {" "}
            <Stack direction={"column"} alignItems={"center"}>
              <SvgIcon>
                {topLevelReached ? <CheckBadgeIcon /> : <ArrowUpIcon />}
              </SvgIcon>
              {!topLevelReached && (
                <Typography
                  color="white"
                  variant="body2"
                  sx={{ fontSize: ".85rem", margin: "0 1 0 1" }}
                >
                  {` ${nextLevel.percentage}%`}
                </Typography>
              )}
            </Stack>
          </Avatar>
        </Stack>
      </CardContent>
    </Card>
  );
};

function NextLevelBadge({ nextLevel }) {
  return (
    <Stack direction={"column"} alignItems={"center"}>
      <SvgIcon>
        <ArrowUpIcon />
      </SvgIcon>
      <Typography
        color="white"
        variant="body2"
        sx={{ fontSize: ".85rem", margin: "0 1 0 1" }}
      >
        {` ${nextLevel.percentage}%`}
      </Typography>
    </Stack>
  );
}

export default CardNextLevel;
