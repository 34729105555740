import React, { useMemo, useState } from "react";
import { Card, CardActions, CardContent, CardHeader } from "@mui/material";
import {
  DataGrid,
  itIT,
  GridToolbar,
  GridActionsCellItem,
} from "@mui/x-data-grid";

import { useNavigate } from "react-router-dom";

import TableGeneratePwdAction from "../TableGeneratePwdAction";
import ModalPasswordGen from "./ModalPasswordGen";

const TableAnagrafica = ({ personData }) => {
  const [paginationState, setPaginationState] = useState(10);
  const [openModal, setOpenModal] = useState({
    open: false,
    id: undefined,
  });
  const navigate = useNavigate();

  const recordsFromApi = personData.map((e) => {
    const addressData =
      e.addresses.filter(
        (e) => e?.addressType === 0 || e?.addressType === 2
      )[0] ?? "";
    return {
      id: e.people.id,
      visual: e.people.visual,
      telephone:
        e.contacts.filter(
          (e) => e?.contactType === 0 || e?.contactType === 1
        )[0]?.contact ?? "",
      email: e.contacts.filter((e) => e?.contactType === 2)[0]?.contact ?? "",
      addressVisual: addressData.visual,
      region: addressData.region,
      level:
        e.personJobs[0].jobType === 4 ? "Nessuno" : e.personalLevel?.name ?? "",
      isActive: e.people.status === 1,
    };
  });

  const onCLick = (data, e) => {
    e.preventDefault();
    navigate(`/anagrafica/${data.id}`, {
      state: personData.filter((e) => e.people.id === data.id)[0],
    });
  };

  const onChangePwd = (id) => {
    return () => {
      setOpenModal({
        open: true,
        id: id,
      });
    };
  };

  const closeModal = () => {
    setOpenModal({
      ...openModal,
      open: false,
    });
  };

  const columns = useMemo(
    () => [
      {
        field: "id",
        headerName: "ID",
        width: 66.8,
      },
      {
        field: "level",
        headerName: "LIVELLO",
        width: 170,
      },

      {
        field: "visual",
        headerName: "NOME",
        width: 200,
      },
      {
        field: "telephone",
        headerName: "TELEFONO",
        width: 140,
      },
      {
        field: "email",
        headerName: "EMAIL",
        width: 250,
      },
      {
        field: "addressVisual",
        headerName: "INDIRIZZO",
        width: 420,
      },
      {
        field: "isActive",
        headerName: "OK?",
        type: "boolean",
      },
      {
        field: "ChangePassword",
        headerName: "Password",
        type: "actions",
        getActions: (params) => {
          return [
            <GridActionsCellItem
              icon={<TableGeneratePwdAction />}
              onClick={onChangePwd(params.id)}
              label="Change Password"
            />,
          ];
        },
      },
    ],
    [personData]
  );

  return (
    <>
      <DataGrid
        showCellVerticalBorder={false}
        localeText={itIT.components.MuiDataGrid.defaultProps.localeText}
        columns={columns}
        rows={recordsFromApi}
        getRowId={(row) => row.id}
        initialState={{
          ...recordsFromApi,
          pagination: {
            paginationModel: { pageSize: paginationState, page: 0 },
          },
        }}
        pageSizeOptions={[5, 10, 20, 50]}
        sx={{
          boxShadow:
            "0px 5px 22px rgba(0, 0, 0, 0.05), 0px 0px 0px 0.5px rgba(0, 0, 0, 0.035)",
          borderRadius: 3,
          border: 0,
        }}
        onRowDoubleClick={onCLick}
        slots={{
          toolbar: () => <GridToolbar sx={{ mb: 2 }} />,
        }}
      />
      <ModalPasswordGen
        open={openModal.open}
        id={openModal.id}
        closeModal={closeModal}
        // newsData={newsData}
        // tokenState={tokenState}
        // isAuthState={isAuthState}
        // setIsAuthState={setIsAuthState}
        // setNewsData={setNewsData}
      />
    </>
  );
};

export default TableAnagrafica;
