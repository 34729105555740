import React, { useState } from "react";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  TextField,
  Stack,
  Typography,
  Unstable_Grid2 as Grid,
} from "@mui/material";

import { postRequestGenerator } from "../../helpers/httpHelper";

const CardAddressDetails = ({
  userState,
  tokenState,
  setIsAuthState,
  addressData,
  personType,
}) => {
  const [modifiedValues, setModifiedValues] = useState({
    id: 0,
    address: undefined,
    locality: undefined,
    prov: undefined,
    region: undefined,
    zip: undefined,
  });
  const [sent, setSent] = useState(false);
  const variant = "outlined";

  const onChangeField = (e) => {
    setModifiedValues({
      ...modifiedValues,
      [e.target.name]: e.target.value.trim(),
      username: userState.username,
    });

    e.preventDefault();
  };

  const onSubmit = (e) => {
    e.preventDefault();

    const patchAddress = postRequestGenerator({
      url: `atomic/addresses/${addressData.id}`,
      queryType: "PATCH",
      tokenState: tokenState,
      isAuthState: setIsAuthState,
      body: modifiedValues,
    });
    patchAddress().then((e) => {
      setSent(true);
      if (
        addressData.prov !== e.body.prov ||
        addressData.region !== e.body.region
      ) {
        setModifiedValues({
          ...modifiedValues,
          prov: e.body.prov,
          region: e.body.region,
        });
      }
    });
  };

  return (
    <Card
      sx={{
        boxShadow:
          "0px 5px 22px rgba(0, 0, 0, 0.05), 0px 0px 0px 0.5px rgba(0, 0, 0, 0.035)",
        borderRadius: 3,
      }}
    >
      {" "}
      <form autoComplete="off" noValidate onSubmit={onSubmit}>
        <CardHeader
          title={personType === 0 ? "Indirizzo residenza" : "Sede legale"}
          titleTypographyProps={{
            variant: "overline",
            fontSize: "1.15rem",
            color: "#424242",
            mb: "-.30rem",
          }}
          subheaderTypographyProps={{
            variant: "caption",
            fontSize: ".75rem",
            color: "#616161",
            mt: "-.55rem",
            mb: ".0rem",
          }}
        />
        <CardContent sx={{ pt: 0 }}>
          <Box sx={{ m: -1.5 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12}>
                <TextField
                  value={modifiedValues?.address ?? addressData?.address ?? ""}
                  onChange={onChangeField}
                  fullWidth={true}
                  color={
                    (modifiedValues?.address?.length ?? "") > 0 && sent
                      ? "success"
                      : "primary"
                  }
                  focused={(modifiedValues?.address?.length ?? "") > 0 && sent}
                  size="small"
                  type="text"
                  name="address"
                  label="indirizzo"
                  variant={variant}
                ></TextField>
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  value={modifiedValues?.zip ?? addressData?.zip ?? ""}
                  onChange={onChangeField}
                  fullWidth={true}
                  color={
                    (modifiedValues?.zip?.length ?? "") > 0 && sent
                      ? "success"
                      : "primary"
                  }
                  focused={(modifiedValues?.zip?.length ?? "") > 0 && sent}
                  size="small"
                  type="text"
                  name="zip"
                  label="cap"
                  variant={variant}
                ></TextField>
              </Grid>
              <Grid item xs={12} md={8}>
                <TextField
                  value={
                    modifiedValues?.locality ?? addressData?.locality ?? ""
                  }
                  onChange={onChangeField}
                  fullWidth={true}
                  color={
                    (modifiedValues?.locality?.length ?? "") > 0 && sent
                      ? "success"
                      : "primary"
                  }
                  focused={(modifiedValues?.locality?.length ?? "") > 0 && sent}
                  size="small"
                  type="text"
                  name="locality"
                  label="comune"
                  variant={variant}
                ></TextField>
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  aria-readonly={true}
                  value={modifiedValues?.prov ?? addressData?.prov ?? ""}
                  fullWidth={true}
                  color={
                    (modifiedValues?.prov?.length ?? "") > 0 && sent
                      ? "success"
                      : "primary"
                  }
                  focused={(modifiedValues?.prov?.length ?? "") > 0 && sent}
                  size="small"
                  type="text"
                  name="prov"
                  label="provincia"
                  variant={variant}
                ></TextField>
              </Grid>
              <Grid item xs={12} md={8}>
                <TextField
                  aria-readonly={true}
                  value={modifiedValues?.region ?? addressData?.region ?? ""}
                  fullWidth={true}
                  color={
                    (modifiedValues?.region?.length ?? "") > 0 && sent
                      ? "success"
                      : "primary"
                  }
                  focused={(modifiedValues?.region?.length ?? "") > 0 && sent}
                  size="small"
                  type="text"
                  name="region"
                  label="regione"
                  variant={variant}
                ></TextField>
              </Grid>
            </Grid>
          </Box>
        </CardContent>
        <Divider />
        <CardActions sx={{ justifyContent: "flex-end" }}>
          <Button
            type="submit"
            variant={sent ? "outlined" : "contained"}
            sx={{ color: sent ? "primary" : "white" }}
            color="primary"
          >
            {sent ? "SALVATO" : "SALVA"}{" "}
          </Button>
        </CardActions>
      </form>
    </Card>
  );
};

export default CardAddressDetails;
