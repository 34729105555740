import React, { useState } from "react";
import { postRequestGenerator } from "../../helpers/httpHelper";
import { user, token, isAuth } from "../../athoms/auth";
import { useRecoilState, useRecoilValue } from "recoil";

import {
  Unstable_Grid2 as Grid,
  TextField,
  Button,
  Typography,
  Divider,
  Stack,
  Box,
  Modal,
  Container,
} from "@mui/material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "2px solid",
  borderColor: "primary.main",
  boxShadow: 24,
  borderRadius: 3,
  pt: 2,
  px: 4,
  pb: 3,
};

const ModalPasswordGen = ({
  open,
  closeModal,
  newsData,
  id,

  setNewsData,
  rowId,
}) => {
  const [newPassword, setnewPassword] = useState("");
  const userState = useRecoilValue(user);
  const tokenState = useRecoilValue(token);
  const [isAuthState, setIsAuthState] = useRecoilState(isAuth);

  const onCloseModal = (e) => {
    closeModal();
    setnewPassword("");
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (isAuthState) {
      const generatePwdApi = postRequestGenerator({
        url: `atomic/people/${id}`,
        queryType: "GENERATE-NEW-PASSWORD",
        tokenState: tokenState,
        isAuthState: setIsAuthState,
        body: {},
      });
      generatePwdApi().then((resp) => {
        setnewPassword(resp.body.password);
      });
    }
  };

  return (
    <Modal
      open={open}
      onClose={closeModal}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
    >
      <form onSubmit={onSubmit}>
        <Box sx={{ ...style, width: 450, maxHeight: "70vh" }}>
          <Stack spacing={2} padding={1}>
            <Typography
              color={"primary.main"}
              id="parent-modal-title"
              sx={{
                fontSize: "1.2rem",
                fontWeight: 500,
              }}
            >
              Generazione nuova password
            </Typography>
            <Divider />
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                verticalAlign: "middle",
              }}
            >
              {" "}
              <Container>
                <Grid
                  container
                  spacing={1}
                  alignItems={"center"}
                  justifyContent={"center"}
                >
                  <Grid item xs={12}>
                    <TextField
                      value={newPassword}
                      disabled={true}
                      required={true}
                      color="primary"
                      size="small"
                      type="text"
                      name="requiredPrice"
                    ></TextField>
                  </Grid>
                </Grid>
              </Container>
            </Box>
            <Divider />
            <Stack direction={"row-reverse"} justifyItems={"flex-end"}>
              <Button
                type="submit"
                disabled={newPassword.length > 1}
                sx={{ color: "white" }}
                variant="contained"
                onSubmit={onSubmit}
              >
                {"GENERA"}
              </Button>
              <Button
                size="small"
                variant="outlined"
                onClick={onCloseModal}
                sx={{
                  marginRight: 2,
                }}
              >
                {"INDIETRO"}
              </Button>
            </Stack>
          </Stack>
        </Box>
      </form>
    </Modal>
  );

  function updateRow() {
    const newData = newsData.map((element) => {
      if (element.news.id === rowId) {
        // element.house.requiredPrice = houseDataToPatch.requiredPrice;
      }
      return element;
    });
    setNewsData(newData);
  }
};

export default ModalPasswordGen;
