import React, { useState, useEffect } from "react";
import {
  Box,
  Container,
  Unstable_Grid2 as Grid,
  Stack,
  useTheme,
  Tab,
  Tabs,
  CircularProgress,
} from "@mui/material";

// import { TabContext, TabPanel } from "@mui/lab";

import { useRecoilState, useRecoilValue } from "recoil";

import { newsStatus } from "../../helpers/enums";
import { getRequestGenerator } from "../../helpers/httpHelper";
import { user, token, isAuth } from "../../athoms/auth";

import { BasicPageLayout } from "../../layouts/BasicPageLayout";
import TableInsertedNews from "./TableUserInsertedNews";
import TableAcquiredNews from "./TableUserAcquiredNews";
import TableSoldNews from "./TableUserSoldNews";
import TableHistoryNews from "./TableUserHistoryNews";

const TableUserNews = () => {
  const userState = useRecoilValue(user);
  const tokenState = useRecoilValue(token);
  const [isAuthState, setIsAuthState] = useRecoilState(isAuth);
  const theme = useTheme();

  const [newsStatusState, setNewsStatusState] = useState(
    newsStatus.indexOf("Nuova")
  );
  const [newsData, setNewsData] = useState([]);

  const handleChange = (e, newValue) => {
    e.preventDefault();
    setNewsStatusState(newValue);
  };

  useEffect(() => {
    if (isAuthState) {
      const getPeopleApi = getRequestGenerator({
        url: "aggregated/news",
        queryType: "GET",
        tokenState: tokenState,
        isAuthState: setIsAuthState,
        filters: {
          'news"."newsStatus': newsStatusState,
          'news"."isActive': 1,
          'news"."commercialFlagExtId': userState.id,
        },
      });
      getPeopleApi().then((e) => {
        setNewsData(e.body.results);
      });
    } else {
      return;
    }
    return () => {
      // Cleanup
    };
  }, [newsStatusState, isAuthState]);

  return (
    <Grid container spacing={3}>
      <Grid xs={12}>
        <Box sx={{ width: "100%", typography: "body1" }}>
          <Tabs
            value={newsStatusState}
            onChange={handleChange}
            textColor="primary"
            indicatorColor="primary"
            aria-label="secondary tabs example"
          >
            <Tab value={newsStatus.indexOf("Nuova")} label={"Nuove"} />

            <Tab
              value={newsStatus.indexOf("Verificata")}
              label={"Verificate"}
            />
            <Tab
              value={newsStatus.indexOf("Mandato Ottenuto")}
              label={"Acquisite"}
            />
            <Tab value={newsStatus.indexOf("Rogito Fatto")} label={"Vendute"} />
            <Tab value={newsStatus.indexOf("Storico")} label={"Storico"} />
          </Tabs>
        </Box>
      </Grid>
      <Grid xs={12}>
        {(newsStatusState === newsStatus.indexOf("Verificata") ||
          newsStatusState === newsStatus.indexOf("Nuova")) && (
          <TableInsertedNews
            newsData={newsData}
            setNewsData={setNewsData}
            tokenState={tokenState}
            isAuthState={isAuthState}
            setIsAuthState={setIsAuthState}
            newsStatusState={newsStatusState}
          />
        )}
        {newsStatusState === newsStatus.indexOf("Mandato Ottenuto") && (
          <TableAcquiredNews
            newsData={newsData}
            setNewsData={setNewsData}
            tokenState={tokenState}
            isAuthState={isAuthState}
            setIsAuthState={setIsAuthState}
            newsStatusState={newsStatusState}
          />
        )}
        {newsStatusState === newsStatus.indexOf("Rogito Fatto") && (
          <TableSoldNews
            newsData={newsData}
            setNewsData={setNewsData}
            tokenState={tokenState}
            isAuthState={isAuthState}
            setIsAuthState={setIsAuthState}
            newsStatusState={newsStatusState}
          />
        )}
        {newsStatusState === newsStatus.indexOf("Storico") && (
          <TableHistoryNews
            newsData={newsData}
            setNewsData={setNewsData}
            tokenState={tokenState}
            isAuthState={isAuthState}
            setIsAuthState={setIsAuthState}
            newsStatusState={newsStatusState}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default TableUserNews;
