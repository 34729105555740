import React, { useState, useEffect } from "react";
import {
  Box,
  Container,
  Unstable_Grid2 as Grid,
  Stack,
  useTheme,
  Tab,
  Tabs,
} from "@mui/material";

import { useRecoilState, useRecoilValue } from "recoil";
import { FormattedDate } from "react-intl";

import { jobType } from "../../../helpers/enums";
import { getRequestGenerator } from "../../../helpers/httpHelper";
import { user, token, isAuth } from "../../../athoms/auth";

import { BasicPageLayout } from "../../../layouts/BasicPageLayout";
import TablePayments from "../../../components/TablePayments";

const PagamentiPage = () => {
  const userState = useRecoilValue(user);
  const tokenState = useRecoilValue(token);
  const [isAuthState, setIsAuthState] = useRecoilState(isAuth);
  const theme = useTheme();

  const paymentState = 0;
  const [paymentsData, setPaymentsData] = useState([]);

  const handleChange = (e, newValue) => {
    e.preventDefault();
    // setPaymentState(newValue);
  };

  useEffect(() => {
    if (isAuthState) {
      const getPayableApi = getRequestGenerator({
        url: "microservices/payments",
        queryType: "GET-PAYABLE",
        tokenState: tokenState,
        isAuthState: setIsAuthState,
        body: {},
      });
      getPayableApi().then((e) => setPaymentsData(e.body.results));
    } else {
      return;
    }
  }, [paymentState, isAuthState]);

  return (
    <BasicPageLayout>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 8.5,
        }}
      >
        <Container maxWidth="xl">
          <Grid container spacing={3}>
            <Grid xs={12}>
              <Box sx={{ width: "100%", typography: "body1" }}>
                <Tabs
                  value={paymentState}
                  onChange={handleChange}
                  textColor="primary"
                  indicatorColor="primary"
                  aria-label="secondary tabs example"
                >
                  <Tab value={0} label={"Da Effettuare"} />
                </Tabs>
              </Box>
            </Grid>
            <Grid xs={12}>
              <TablePayments
                paymentsData={paymentsData}
                setPaymentsData={setPaymentsData}
                tokenState={tokenState}
                setIsAuthState={setIsAuthState}
                isAuthState={isAuthState}
              />
            </Grid>
          </Grid>
        </Container>
      </Box>
    </BasicPageLayout>
  );
};

export default PagamentiPage;
