import React, { useState, useEffect } from "react";
import {
  Box,
  Container,
  Unstable_Grid2 as Grid,
  Stack,
  useTheme,
  Tab,
  Tabs,
} from "@mui/material";

import { useRecoilState, useRecoilValue } from "recoil";
import { FormattedDate } from "react-intl";

import { jobType } from "../../../helpers/enums";
import { getRequestGenerator } from "../../../helpers/httpHelper";
import { user, token, isAuth } from "../../../athoms/auth";

import { BasicPageLayout } from "../../../layouts/BasicPageLayout";
import TableAnagrafica from "../../../components/TableAnagrafica";
import TableAnagraficaPending from "../../../components/TableAnagraficaPending";

const AnagraficaPage = () => {
  const userState = useRecoilValue(user);
  const tokenState = useRecoilValue(token);
  const [isAuthState, setIsAuthState] = useRecoilState(isAuth);
  const theme = useTheme();

  const [jobTypeState, setJobTypeState] = useState(
    jobType.indexOf("Segnalatore")
  );
  const [peopleData, setPeopleData] = useState([]);

  const handleChange = (e, newValue) => {
    e.preventDefault();
    setJobTypeState(newValue);
  };

  useEffect(() => {
    if (isAuthState) {
      if (jobTypeState === 100) {
        //Get le persone senza contratto
        const getPeopleApi = getRequestGenerator({
          url: "atomic/people",
          queryType: "GET-NO-CONTRACT",
          tokenState: tokenState,
          isAuthState: setIsAuthState,
        });
        getPeopleApi().then((e) =>
          setPeopleData(converterNoContractPeopleData(e))
        );
      } else {
        const getPeopleApi = getRequestGenerator({
          url: "aggregated/people",
          queryType:
            jobTypeState === jobType.indexOf("Cliente")
              ? "GET-LEFT-JOIN"
              : "GET-INNER-JOIN",
          tokenState: tokenState,
          isAuthState: setIsAuthState,
          filters: { 'personJobs"."jobType': jobTypeState },
        });
        getPeopleApi().then((e) => setPeopleData(e.body.results));
      }
    } else {
      return;
    }
  }, [jobTypeState, isAuthState]);

  return (
    <BasicPageLayout>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 8.5,
        }}
      >
        <Container maxWidth="xl">
          <Grid container spacing={3}>
            <Grid xs={12}>
              <Box sx={{ width: "100%", typography: "body1" }}>
                <Tabs
                  value={jobTypeState}
                  onChange={handleChange}
                  textColor="primary"
                  indicatorColor="primary"
                  aria-label="secondary tabs example"
                >
                  <Tab value={100} label={"Senza Contratto"} />
                  <Tab
                    value={jobType.indexOf("Segnalatore")}
                    label={"Segnalatori"}
                  />
                  <Tab
                    value={jobType.indexOf("Dipendente")}
                    label={"Dipendenti"}
                  />
                  <Tab value={jobType.indexOf("Cliente")} label={"Clienti"} />
                </Tabs>
              </Box>
            </Grid>
            {jobTypeState !== 100 && (
              <Grid xs={12}>
                <TableAnagrafica personData={peopleData} />
              </Grid>
            )}
            {jobTypeState === 100 && (
              <Grid xs={12}>
                <TableAnagraficaPending
                  personData={peopleData}
                  setPeopleData={setPeopleData}
                />
              </Grid>
            )}
          </Grid>
        </Container>
      </Box>
    </BasicPageLayout>
  );
};

function converterNoContractPeopleData(peopleData) {
  const objectResults = peopleData.body.results.map((e) => {
    const obj = JSON.parse(e.body);
    return {
      ...obj,
      people: {
        ...obj.people,
        id: e.id,
        visual:
          obj.people.surname + " " + obj.people.name || obj.people.legalName,
        status: 1,
      },
    };
  });
  return objectResults;
}

export default AnagraficaPage;
