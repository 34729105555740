import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Typography } from "@mui/material";

import { user, token, isAuth } from "../../athoms/auth";
import { useRecoilState, useRecoilValue } from "recoil";
import { postRequestGenerator } from "../../helpers/httpHelper";

export default function ModalContract({
  open,
  personData,
  handleClose,
  deleteRow,
}) {
  const [okToSend, setOkToSend] = useState(false);
  const tokenState = useRecoilValue(token);
  const [isAuthState, setIsAuthState] = useRecoilState(isAuth);

  useEffect(() => {
    if (isAuthState && okToSend) {
      const sendNewCommercialFlag = postRequestGenerator({
        url: "aggregated/people",
        queryType: "POST",
        tokenState: tokenState,
        isAuthState: setIsAuthState,
        body: personData,
      });
      const deleteRecordApi = postRequestGenerator({
        url: `atomic/people/${personData.people.id}`,
        queryType: "DELETE-NO-CONTRACT",
        tokenState: tokenState,
        isAuthState: setIsAuthState,
        body: {},
      });
      sendNewCommercialFlag()
        .then((e) => deleteRecordApi())
        .then((e) => {
          deleteRow(personData.people.id);
          handleClose();
        });
    } else {
      return;
    }
  }, [okToSend, isAuthState]);

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <Typography>
            {"Confermi che il contratto è stato firmato?"}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description"></DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Annulla</Button>
          <Button onClick={() => setOkToSend(true)} autoFocus>
            Confermo
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
