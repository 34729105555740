import React, { useState, useEffect } from "react";
import {
  Box,
  Container,
  Unstable_Grid2 as Grid,
  Stack,
  useTheme,
} from "@mui/material";
import { useRecoilState, useRecoilValue } from "recoil";
import { FormattedDate } from "react-intl";

import { getRequestGenerator } from "../../helpers/httpHelper";
import { user, token, isAuth } from "../../athoms/auth";

import { BasicPageLayout } from "../../layouts/BasicPageLayout";
import CardPieEarnings from "../../components/CardPieEarnings";
import CardRealGain from "../../components/CardRealGain";
import CardPayedGain from "../../components/CardPayedGain";
import CardPotentialGain from "../../components/CardPotentialGain";
import CardInvoicedGain from "../../components/CardInvoicedGain";
import CardGenerateInvoice from "../../components/CardGenerateInvoice";

const Guadagni = () => {
  const [earnings, setEarnings] = useState({
    personExtId: 0,
    theoreticalGain: 0.0,
    realGain: 0.0,
    invoicedGain: 0.0,
    payedGain: 0.0,
  });
  const userState = useRecoilValue(user);
  const tokenState = useRecoilValue(token);
  const [isAuthState, setIsAuthState] = useRecoilState(isAuth);
  const theme = useTheme();
  const [isInvoiceable, invoiceableDate] = checkIfIsInvoiceable();

  useEffect(() => {
    if (isAuthState) {
      const getPersonAggregate = getRequestGenerator({
        url: `atomic/earnings/${userState.id}`,
        queryType: "GET",
        tokenState: tokenState,
        isAuthState: setIsAuthState,
      });
      getPersonAggregate().then((e) => {
        setEarnings(e.body);
      });
    } else {
      return;
    }
  }, [isAuthState]);

  return (
    <BasicPageLayout>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 8.5,
        }}
      >
        <Container maxWidth="xl">
          <Grid container spacing={3}>
            <Grid xs={12} sm={6} lg={4}>
              <CardPotentialGain
                earnings={earnings}
                sx={{
                  height: "100%",
                  boxShadow:
                    "0px 5px 22px rgba(0, 0, 0, 0.05), 0px 0px 0px 0.5px rgba(0, 0, 0, 0.035)",
                  borderRadius: 3,
                }}
              />
            </Grid>
            <Grid xs={12} sm={6} lg={4}>
              <CardRealGain
                earnings={earnings}
                sx={{
                  height: "100%",
                  boxShadow:
                    "0px 5px 22px rgba(0, 0, 0, 0.05), 0px 0px 0px 0.5px rgba(0, 0, 0, 0.035)",
                  borderRadius: 3,
                }}
              />
            </Grid>
            {/* <Grid xs={12} sm={6} lg={3}>
              <CardInvoicedGain
                earnings={earnings}
                sx={{
                  height: "100%",
                  boxShadow:
                    "0px 5px 22px rgba(0, 0, 0, 0.05), 0px 0px 0px 0.5px rgba(0, 0, 0, 0.035)",
                  borderRadius: 3,
                }}
              />
            </Grid> */}
            <Grid xs={12} sm={6} lg={4}>
              <CardPayedGain
                earnings={earnings}
                sx={{
                  height: "100%",
                  boxShadow:
                    "0px 5px 22px rgba(0, 0, 0, 0.05), 0px 0px 0px 0.5px rgba(0, 0, 0, 0.035)",
                  borderRadius: 3,
                }}
              />
            </Grid>
            <Grid xs={12} lg={7}>
              <CardGenerateInvoice
                tokenState={tokenState}
                isAuthState={isAuthState}
                setIsAuthState={setIsAuthState}
              />
            </Grid>
            <Grid xs={12} lg={5}>
              <CardPieEarnings
                earnings={earnings}
                labels={["Potenziale", "Reale"]}
                sx={{
                  boxShadow:
                    "0px 5px 22px rgba(0, 0, 0, 0.05), 0px 0px 0px 0.5px rgba(0, 0, 0, 0.035)",
                  borderRadius: 3,
                }}
              />
            </Grid>
          </Grid>
        </Container>
      </Box>
    </BasicPageLayout>
  );
};

function checkIfIsInvoiceable() {
  const dateNow = new Date();
  if (!dateNow.getDate() >= 5) {
    console.log("bottone");
    return [true, dateNow];
  } else {
    const newDate = new Date(dateNow);
    newDate.setMonth(dateNow.getMonth() + 1);
    newDate.setDate(1);
    return [false, newDate];
  }
}

export default Guadagni;
