import React, { useState, useEffect } from "react";
import {
  Box,
  Container,
  Unstable_Grid2 as Grid,
  Stack,
  useTheme,
} from "@mui/material";
import { useRecoilState, useRecoilValue } from "recoil";

import { BasicPageLayout } from "../../layouts/BasicPageLayout";
import ChildrenGraph from "../../components/ChildrenGraph";
import CardChildren from "../../components/CardChildren";
import { getRequestGenerator } from "../../helpers/httpHelper";
import { user, token, isAuth } from "../../athoms/auth";
import CardChildrenInProduction from "../../components/CardChildrenInProduction";

const DownLine = () => {
  const [childrenList, setChildrenList] = useState([]);
  const userState = useRecoilValue(user);
  const tokenState = useRecoilValue(token);
  const [isAuthState, setIsAuthState] = useRecoilState(isAuth);
  // const theme = useTheme();

  useEffect(() => {
    if (isAuthState) {
      const getPersonAggregate = getRequestGenerator({
        url: `aggregated/people/${userState.id}`,
        queryType: "GET-CHILDREN",
        tokenState: tokenState,
        isAuthState: setIsAuthState,
      });
      getPersonAggregate().then((e) => {
        setChildrenList([...e.body.results]);
      });
    } else {
      return;
    }
  }, [isAuthState]);

  return (
    <BasicPageLayout>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 8.5,
        }}
      >
        <Container maxWidth="xl">
          <Grid container spacing={4}>
            <Grid md={6} lg={6} xl={6} sm={12} xs={12}>
              <CardChildren
                graphChildren={
                  childrenList.filter((e) => e.personalLevelExtId === 1).length
                }
                sx={{
                  height: "100%",
                  boxShadow:
                    "0px 5px 22px rgba(0, 0, 0, 0.05), 0px 0px 0px 0.5px rgba(0, 0, 0, 0.035)",
                  borderRadius: 3,
                }}
              />
            </Grid>
            <Grid md={6} lg={6} xl={6} sm={12} xs={12}>
              <CardChildrenInProduction
                graphChildren={
                  childrenList.filter((e) => e.personalLevelExtId > 1).length
                }
                sx={{
                  height: "100%",
                  boxShadow:
                    "0px 5px 22px rgba(0, 0, 0, 0.05), 0px 0px 0px 0.5px rgba(0, 0, 0, 0.035)",
                  borderRadius: 3,
                }}
              />
            </Grid>

            <Grid xs={12}>
              <ChildrenGraph
                userFromState={userState}
                apiResponse={childrenList}
              />
            </Grid>
          </Grid>
        </Container>
      </Box>
    </BasicPageLayout>
  );
};

export default DownLine;
