import React, { useState } from "react";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  TextField,
  Unstable_Grid2 as Grid,
} from "@mui/material";

import { postRequestGenerator } from "../../helpers/httpHelper";


const CardUserDetails = ({ userState, tokenState, setIsAuthState }) => {
  const [modifiedValues, setModifiedValues] = useState({
    username: userState.username,
    password: "",
    newPassword: "",
    retypePassword: "",
  });
  const [sent, setSent] = useState(false);
  const variant = "outlined";

  const onChangeField = (e) => {
    setModifiedValues({
      ...modifiedValues,
      [e.target.name]: e.target.value.trim(),
      username: userState.username,
      id: userState.id,
    });

    e.preventDefault();
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (
      modifiedValues.username &&
      modifiedValues.password &&
      modifiedValues.newPassword &&
      modifiedValues.newPassword === modifiedValues.retypePassword
    ) {
      const patchPerson = postRequestGenerator({
        url: `atomic/people`,
        queryType: "CHANGE-PASSWORD",
        tokenState: tokenState,
        isAuthState: setIsAuthState,
        body: {
          username: modifiedValues.username,
          password: modifiedValues.password,
          newPassword: modifiedValues.newPassword,
        },
      });
      patchPerson()
        .then(() => setSent(true))
        .catch((e) => alert("Errore nella compilazione dei campi utente"));
    } else {
      alert("Errore nella compilazione dei campi utente");
    }
  };

  return (
    <Card
      sx={{
        boxShadow:
          "0px 5px 22px rgba(0, 0, 0, 0.05), 0px 0px 0px 0.5px rgba(0, 0, 0, 0.035)",
        borderRadius: 3,
      }}
    >
      {" "}
      <form autoComplete="off" noValidate onSubmit={onSubmit}>
        <CardHeader
          subheader={`Non condividere con nessuno`}
          title="Dati Utente"
          titleTypographyProps={{
            variant: "overline",
            fontSize: "1.15rem",
            color: "#424242",
          }}
          subheaderTypographyProps={
            {
              variant: "caption",
              fontSize: ".75rem",
              color: "#616161",
              mt: "-.55rem",
              mb: ".0rem"
            }
          }
        />
        <CardContent sx={{ pt: 0 }}>
          <Box sx={{ m: -1.5 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12}>
                <TextField
                  value={userState.username ?? ""}
                  fullWidth={true}
                  aria-readonly={true}
                  color="primary"
                  size="small"
                  type="text"
                  name="personType"
                  label="username"
                  variant={variant}
                ></TextField>
              </Grid>
              <Grid item xs={12} md={12}>
                <TextField
                  value={modifiedValues?.password ?? ""}
                  onChange={onChangeField}
                  fullWidth={true}
                  color={"primary"}
                  size="small"
                  type="password"
                  name="password"
                  label="vecchia password"
                  variant={variant}
                ></TextField>
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  value={modifiedValues?.newPassword ?? ""}
                  onChange={onChangeField}
                  fullWidth={true}
                  color={sent ? "success" : "primary"}
                  focused={sent}
                  size="small"
                  type="password"
                  name="newPassword"
                  label="nuova password"
                  variant={variant}
                ></TextField>
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  value={modifiedValues?.retypePassword ?? ""}
                  onChange={onChangeField}
                  fullWidth={true}
                  color={"primary"}
                  error={
                    modifiedValues.retypePassword !==
                      modifiedValues.newPassword &&
                    modifiedValues.retypePassword.length > 0
                  }
                  helperText={
                    modifiedValues.retypePassword !==
                      modifiedValues.newPassword &&
                    modifiedValues.retypePassword.length > 0
                      ? "la password non corrisponde"
                      : ""
                  }
                  size="small"
                  type="password"
                  name="retypePassword"
                  label="ripeti password"
                  variant={variant}
                ></TextField>
              </Grid>
            </Grid>
          </Box>
        </CardContent>
        <Divider />
        <CardActions sx={{ justifyContent: "flex-end" }}>
          <Button
            type="submit"
            variant={sent ? "outlined" : "contained"}
            sx={{ color: sent ? "primary" : "white" }}
            color="primary"
          >
            {sent ? "SALVATO" : "SALVA"}{" "}
          </Button>
        </CardActions>
      </form>
    </Card>
  );
};

export default CardUserDetails;
