import ChartBarIcon from "@heroicons/react/24/solid/ChartBarIcon";
import CogIcon from "@heroicons/react/24/solid/CogIcon";
import ShareIcon from "@heroicons/react/24/solid/ShareIcon";
import CurrencyEuroIcon from "@heroicons/react/24/solid/CurrencyEuroIcon";
import HomeModernIcon from "@heroicons/react/24/solid/HomeModernIcon";

import BuildingLibraryIcon from "@heroicons/react/24/outline/BuildingLibraryIcon";
import UsersIcon from "@heroicons/react/24/solid/UsersIcon";
import PresentationChartBarIcon from "@heroicons/react/24/solid/PresentationChartBarIcon";
import BanknotesIcon from "@heroicons/react/24/solid/BanknotesIcon";

import { SvgIcon } from "@mui/material";

export const items = [
  {
    title: "Panoramica",
    path: "/",
    perm: 4,
    icon: (
      <SvgIcon fontSize="small">
        <ChartBarIcon />
      </SvgIcon>
    ),
  },
  {
    title: "Notizie",
    path: "/notizie",
    perm: 4,
    icon: (
      <SvgIcon fontSize="small">
        <HomeModernIcon />
      </SvgIcon>
    ),
  },
  {
    title: "Downline",
    path: "/downline",
    perm: 4,
    icon: (
      <SvgIcon fontSize="small">
        <ShareIcon />
      </SvgIcon>
    ),
  },
  {
    title: "Portafoglio",
    path: "/portafoglio",
    perm: 4,
    icon: (
      <SvgIcon fontSize="small">
        <CurrencyEuroIcon />
      </SvgIcon>
    ),
  },
  {
    title: "Impostazioni",
    path: "/impostazioni",
    perm: 4,
    icon: (
      <SvgIcon fontSize="small">
        <CogIcon />
      </SvgIcon>
    ),
  },
  {
    title: "Anagrafica",
    path: "/anagrafica",
    perm: 1,
    icon: (
      <>
        <SvgIcon fontSize="small">
          <BuildingLibraryIcon />
        </SvgIcon>
        {/* <SvgIcon fontSize="small">
          <UsersIcon />
        </SvgIcon> */}
      </>
    ),
  },
  {
    title: "Gestione Notizie",
    path: "/gestione-notizie",
    perm: 1,
    icon: (
      <>
        <SvgIcon fontSize="small">
          <BuildingLibraryIcon />
        </SvgIcon>
        {/* <SvgIcon fontSize="small">
          <PresentationChartBarIcon />
        </SvgIcon> */}
      </>
    ),
  },
  {
    title: "Pagamenti",
    path: "/pagamenti",
    perm: 1,
    icon: (
      <>
        <SvgIcon fontSize="small">
          <BuildingLibraryIcon />
        </SvgIcon>
        {/* <SvgIcon fontSize="small">
          <BanknotesIcon />
        </SvgIcon> */}
      </>
    ),
  },
];
