import PlusIcon from "@heroicons/react/24/solid/PlusIcon";
import HomeModernIcon from "@heroicons/react/24/solid/HomeModernIcon";
import {
  Avatar,
  Card,
  CardContent,
  Stack,
  SvgIcon,
  Typography,
  Button,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

const CardInsertNews = (props) => {
  const navigate = useNavigate();
  const { sx } = props;

  const onClick = (e) => {
    e.preventDefault();
    navigate("/notizie");
  };

  return (
    <Card sx={sx}>
      <CardContent>
        <Stack
          alignItems="flex-start"
          direction="row"
          justifyContent="space-between"
          spacing={3}
        >
          <Avatar
            sx={{
              border: "1px solid #ffac33",
              backgroundColor: "#ffac33",
              height: 48,
              width: 48,
            }}
          >
            <Stack
              direction={"row"}
              justifyContent={"space-around"}
              alignItems={"center"}
            >
              <SvgIcon sx={{ width: "40%" }}>
                <PlusIcon />
              </SvgIcon>
              <SvgIcon>
                <HomeModernIcon />
              </SvgIcon>
            </Stack>
          </Avatar>
          <Stack spacing={3} justifyContent="center" alignItems="center">
            <Typography color="#616161" variant="overline"></Typography>
            <Button
              onClick={onClick}
              variant="outlined"
              sx={{
                color: "primary.main",
                borderColor: "primary.main",
                "&:hover": {
                  color: "#ffac33",
                  borderColor: "#ffac33",
                },
              }}
            >
              {"INSERISCI NOTIZIA"}
            </Button>
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  );
};

export default CardInsertNews;
