import { useState } from "react";
import PlusIcon from "@heroicons/react/24/solid/PlusIcon";
import UsersIcon from "@heroicons/react/24/solid/UsersIcon";
import {
  Avatar,
  Card,
  CardContent,
  Stack,
  SvgIcon,
  Typography,
  Button,
  Modal,
  Box,
  Divider,
} from "@mui/material";
import { ReactId } from "reactjs-id";

import { frontendUrl } from "../../helpers/config";
import { hashUrl, hashUrlChecker } from "../../helpers/cryptoHelper";

const CardInsertChild = (props) => {
  const { sx, personAggregate } = props;
  const [open, setOpen] = useState(false);
  const uuid = ReactId();
  const hashedUrl = hashUrl(personAggregate.people.id);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const onClick = (e) => {
    e.preventDefault();
    navigator.clipboard.writeText(
      `${frontendUrl}/signUp/${hashedUrl}/${personAggregate.people.id}`
    );
    handleClose();
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid",
    borderColor: "primary.main",
    boxShadow: 24,
    borderRadius: 3,
    pt: 2,
    px: 4,
    pb: 3,
  };

  return (
    <>
      <Card sx={sx} onClick={handleOpen}>
        <CardContent>
          <Stack
            alignItems="flex-start"
            direction="row"
            justifyContent="space-between"
            spacing={3}
          >
            <Avatar
              sx={{
                border: "1px solid secondary.main",
                backgroundColor: "secondary.main",
                height: 48,
                width: 48,
              }}
            >
              {" "}
              <Stack
                direction={"row"}
                justifyContent={"space-around"}
                alignItems={"center"}
              >
                <SvgIcon>
                  <UsersIcon />
                </SvgIcon>
                <SvgIcon sx={{ width: "40%" }}>
                  <PlusIcon />
                </SvgIcon>
              </Stack>
            </Avatar>
            <Stack spacing={3} justifyContent="center" alignItems="center">
              <Typography color="#616161" variant="overline"></Typography>
              <Button
                variant="outlined"
                sx={{
                  color: "primary.main",
                  borderColor: "primary.main",

                  "&:hover": {
                    color: "secondary.main",
                    borderColor: "secondary.main",
                  },
                }}
              >
                {"INVITA AMICO"}
              </Button>
            </Stack>
          </Stack>
        </CardContent>
      </Card>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box sx={{ ...style, width: 400 }}>
          <Stack spacing={2} padding={1}>
            <Typography
              color={"primary.main"}
              id="parent-modal-title"
              sx={{
                fontSize: "1.2rem",
                fontWeight: 500,
              }}
            >
              LINK PER ISCRIZIONE
            </Typography>
            <Divider />
            <Box
              sx={{
                overflowY: "scroll",
                height: "2.3rem",
                display: "flex",
                alignItems: "center",
                verticalAlign: "middle",
              }}
            >
              <Typography
                onClick={onClick}
                variant="body1"
                id="parent-modal-description"
                sx={{
                  fontSize: "0.95rem",
                  fontWeight: 400,
                  color: "#626262",
                  cursor: "pointer",
                }}
              >
                {`${frontendUrl}/signUp/${hashedUrl}/${personAggregate.people.id}`}
              </Typography>
            </Box>
            <Divider />
            <Stack direction={"row-reverse"} justifyItems={"flex-end"}>
              <Button
                size="small"
                sx={{ color: "white" }}
                variant="contained"
                onClick={onClick}
              >
                {"COPIA"}
              </Button>
            </Stack>
          </Stack>
        </Box>
      </Modal>
    </>
  );
};

export default CardInsertChild;
