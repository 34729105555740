import PropTypes from "prop-types";
import { useLocation } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { user } from "../../athoms/auth";

import {
  Box,
  Divider,
  Drawer,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
  Link,
} from "@mui/material";

import { Logo } from "../../components/Logo";
import { items } from "./config";
import { SideNavItem } from "./side-nav-item";

export const SideNav = (props) => {
  const { open, onClose } = props;
  const pathname = useLocation().pathname;
  const lgUp = useMediaQuery((theme) => theme.breakpoints.up("lg"));
  const theme = useTheme();
  const userState = useRecoilValue(user);

  const content = (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
      }}
    >
      <Box sx={{ p: 3 }}>
        <Box
          component="a"
          href="/"
          sx={{
            display: "inline-flex",
            height: 32,
            width: 32,
          }}
        >
          <Logo />
        </Box>
      </Box>
      <Divider sx={{ borderColor: "neutral.700" }} />
      <Box
        component="nav"
        sx={{
          flexGrow: 1,
          px: 2,
          py: 3,
          backgroundColor: "#c0b7a5",
        }}
        // backgroundColor={theme.palette.primary.main}
      >
        <Stack
          component="ul"
          spacing={0.5}
          sx={{
            listStyle: "none",
            p: 0,
            m: 0,
          }}
        >
          {items.map((item) => {
            const active = item.path ? pathname === item.path : false;
            if (userState.permission <= item.perm) {
              return (
                <SideNavItem
                  active={active}
                  disabled={item.disabled}
                  external={item.external}
                  icon={item.icon}
                  key={item.title}
                  path={item.path}
                  title={item.title}
                />
              );
            }
          })}
        </Stack>
      </Box>
      <Divider sx={{ borderColor: "neutral.700" }} />

      <Box
        sx={{
          px: 2,
          py: 3,
        }}
      >
        <Typography
          color={theme.palette.primary.main}
          fontSize={11}
          fontFamily={theme.typography.fontFamily}
          variant="body2"
        >
          App Sviluppata da{" "}
          <Link title="Sviluppo App" underline="none" href="https://mfh.one">
            MFH Srls
          </Link>
          {" & "}
          <Link
            title="Andrea Maccagno"
            underline="none"
            href="https://www.andreamaccagno.com"
          >
            Andrea Maccagno
          </Link>{" "}
        </Typography>
      </Box>
    </Box>
  );

  if (lgUp) {
    return (
      <Drawer
        anchor="left"
        open
        PaperProps={{
          sx: {
            backgroundColor: "main.primary",
            color: "common.white",
            width: 280,
          },
        }}
        variant="permanent"
      >
        {content}
      </Drawer>
    );
  }

  return (
    <Drawer
      anchor="right"
      onClose={onClose}
      open={open}
      PaperProps={{
        sx: {
          backgroundColor: "neutral.800",
          color: "common.white",
          width: 280,
        },
      }}
      sx={{ zIndex: (theme) => theme.zIndex.appBar + 100 }}
      variant="temporary"
    >
      {content}
    </Drawer>
  );
};

SideNav.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
};
