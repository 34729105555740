import React, { useState, useEffect } from "react";
import {
  Box,
  Container,
  Unstable_Grid2 as Grid,
  Stack,
  Button,
} from "@mui/material";
import { useRecoilState, useRecoilValue } from "recoil";

import { BasicPageLayout } from "../../../layouts/BasicPageLayout";
import CardPersonDetails from "../../../components/CardPersonDetails";
import CardUserDetails from "../../../components/CardUserDetails";
import CardAddressDetails from "../../../components/CardAddressDetails";
import CardContactsDetails from "../../../components/CardContactsDetails";

import { postRequestGenerator } from "../../../helpers/httpHelper";
import { token, isAuth } from "../../../athoms/auth";
import { useLocation } from "react-router-dom";
import { jobType } from "../../../helpers/enums";

const AnagraficaEdit = () => {
  const tokenState = useRecoilValue(token);
  const [isAuthState, setIsAuthState] = useRecoilState(isAuth);
  const personAggregate = useLocation().state;

  const [isEmployee, setIsEmployee] = useState(
    personAggregate.personJobs.filter(
      (e) => e.jobType === jobType.indexOf("Dipendente")
    ).length > 0 || personAggregate.people.permission === 1
  );
  const userState = personAggregate.people;
  const [isDeleted, setIsDeleted] = useState(userState.status === 0);

  const onClickEmployee = (e) => {
    const patchPerson = postRequestGenerator({
      url: `aggregated/people/${userState.id}`,
      queryType: "ADD-JOBS",
      tokenState: tokenState,
      isAuthState: setIsAuthState,
      body: {
        personJobs: [
          {
            jobType: jobType.indexOf("Dipendente"),
          },
        ],
      },
    });
    const patchPermission = postRequestGenerator({
      url: `atomic/people/${userState.id}`,
      queryType: "PATCH",
      tokenState: tokenState,
      isAuthState: setIsAuthState,
      body: { permission: 1 },
    });
    Promise.all([patchPerson(), patchPermission()]).then(() =>
      setIsEmployee(true)
    );
    e.preventDefault();
  };

  const onClickDelete = (e) => {
    e.preventDefault();
    const patchStatus = postRequestGenerator({
      url: `atomic/people/${userState.id}`,
      queryType: "PATCH",
      tokenState: tokenState,
      isAuthState: setIsAuthState,
      body: { status: 0 },
    });
    const removeFromTree = postRequestGenerator({
      url: `atomic/peopleTree/${userState.id}`,
      queryType: "REMOVE-FROM-TREE",
      tokenState: tokenState,
      isAuthState: setIsAuthState,
      body: {},
    });
    Promise.all([patchStatus(), removeFromTree()]).then(() =>
      setIsDeleted(true)
    );
  };

  return (
    <BasicPageLayout>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 8.5,
        }}
      >
        <Container maxWidth="xl">
          <Stack justifyContent={"center"} alignItems={"start"}>
            <Stack direction={"row"}>
              {personAggregate.people.permission <= 4 && (
                <Button
                  onClick={onClickEmployee}
                  disabled={isEmployee || isDeleted}
                  size="medium"
                  variant="contained"
                  sx={{
                    marginRight: { lg: "auto" },
                    mb: 3,
                    color: "white",
                  }}
                >
                  {isEmployee
                    ? "ATTUALMENTE DIPENDENTE"
                    : "TRASFORMA IN DIPENDENTE"}
                </Button>
              )}
              <Button
                color="error"
                onClick={onClickDelete}
                disabled={isDeleted}
                size="medium"
                variant="contained"
                sx={{
                  marginRight: { lg: "auto" },
                  mb: 3,
                  color: "white",
                  ml: 2,
                }}
              >
                {isDeleted ? "ACCOUNT ELIMINATO" : "ELIMINA ACCOUNT"}
              </Button>
            </Stack>

            <Grid container spacing={3}>
              <Grid xs={12} md={6} lg={6}>
                <CardPersonDetails
                  personData={personAggregate.people}
                  userState={userState}
                  tokenState={tokenState}
                  setIsAuthState={setIsAuthState}
                />
              </Grid>
              <Grid xs={12} md={6} lg={6}>
                <CardUserDetails
                  userState={userState}
                  tokenState={tokenState}
                  setIsAuthState={setIsAuthState}
                />
              </Grid>
              <Grid xs={12} md={6} lg={6}>
                <CardAddressDetails
                  personType={personAggregate.people.personType}
                  addressData={
                    personAggregate.addresses.filter(
                      (e) => e.addressType === 0 || e.addressType === 2
                    )[0]
                  }
                  userState={userState}
                  tokenState={tokenState}
                  setIsAuthState={setIsAuthState}
                />
              </Grid>
              <Grid xs={12} md={6} lg={6}>
                <CardContactsDetails
                  email={
                    personAggregate.contacts.filter(
                      (e) => e.contactType === 2
                    )[0] ?? null
                  }
                  telephone={
                    personAggregate.contacts.filter(
                      (e) => e.contactType === 0 || e.contactType === 1
                    )[0]
                  }
                  userState={userState}
                  tokenState={tokenState}
                  setIsAuthState={setIsAuthState}
                />
              </Grid>
            </Grid>
          </Stack>
        </Container>
      </Box>
    </BasicPageLayout>
  );
};

export default AnagraficaEdit;
