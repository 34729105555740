import React, { useEffect, useMemo, useState } from "react";
import {
  DataGrid,
  itIT,
  GridToolbar,
  GridActionsCellItem,
} from "@mui/x-data-grid";

import { FormattedDate, FormattedNumber } from "react-intl";
import { newsStatus } from "../../helpers/enums";

import { postRequestGenerator } from "../../helpers/httpHelper";
import TableDeleteAction from "../TableDeleteAction";
import TablePaymentAction from "../TablePaymentAction";
import ModalPaymentNews from "./ModalPaymentNews";

const TableSoldNews = ({
  newsData,
  setNewsData,
  tokenState,
  isAuthState,
  setIsAuthState,
  newsStatusState,
}) => {
  const [paginationState, setPaginationState] = useState(10);
  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState({
    open: false,
    id: undefined,
  });

  const [newRequiredPrice, setNewRequiredPrice] = useState(0.0);

  const recordsFromApi = newsData.map((e) => {
    return {
      completeObject: e,
      newsId: e.news.id,
      registrationDate: e.house.registrationDate,
      commercialFlag: e.commercialFlag.visual,
      owner: e.owner.visual,
      ownerContact: e.ownerContact.contact,
      selledPrice: e.house.selledPrice,
      sellingDate: e.news.sellingDate,
      agreedCommission: e.news.agreedCommission,
    };
  });

  const onDelete = (id) => {
    return () => {
      const deleteApi = postRequestGenerator({
        url: `atomic/news/${id}`,
        queryType: "PATCH",
        tokenState: tokenState,
        isAuthState: setIsAuthState,
        body: { isActive: 0 },
      });
      setLoading(true);
      deleteApi().then((e) => {
        setNewsData(newsData.filter((e) => e.news.id !== id));
        setLoading(false);
      });
    };
  };

  const closeModal = () => {
    setOpenModal({
      ...openModal,
      open: false,
    });
  };

  const onSell = (id) => {
    return () => {
      setOpenModal({
        open: true,
        id: id,
      });
    };
  };

  const onCLick = (data, e) => {
    e.preventDefault();

    // navigate(`/anagrafica/${data.id}`, {
    //   state: personData.filter((e) => e.people.id === data.id)[0],
    // });
  };

  const columns = useMemo(
    () => [
      {
        field: "newsId",
        headerName: "ID",
        width: 66.8,
      },
      {
        field: "registrationDate",
        headerName: "DATA",
        renderCell: (e) => <FormattedDate value={e.value} />,
        width: 80,
      },
      {
        field: "commercialFlag",
        headerName: "SEGNALATORE",
        width: 190,
      },
      {
        field: "owner",
        headerName: "PROPRIETARIO",
        width: 190,
      },
      {
        field: "ownerContact",
        headerName: "CONTATTO",
        width: 140,
      },
      {
        field: "sellingDate",
        headerName: "DATA VENDITA",
        width: 135,

        renderCell: (e) => <FormattedDate value={e.value} />,
      },

      {
        field: "agreedCommission",
        headerName: "PROVVIGIONE",
        width: 115,
        renderCell: (e) => <>{`${e.value}%`}</>,
      },

      {
        field: "selledPrice",
        headerName: "PREZZO VENDITA",
        width: 340,
        maxWidth: 340,
        renderCell: (e) => (
          <>
            <FormattedNumber value={e.value} />
            {" €"}
          </>
        ),
      },

      {
        field: "acquisisci",
        type: "actions",
        getActions: (params) => {
          return [
            <GridActionsCellItem
              icon={<TablePaymentAction />}
              onClick={onSell(params.id)}
              label="Sell"
            />,
            // <GridActionsCellItem
            //   icon={<TableDeleteAction />}
            //   onClick={onDelete(params.id)}
            //   label="Delete"
            // />,
          ];
        },
      },
    ],
    [newsData]
  );

  return (
    <>
      <DataGrid
        loading={loading}
        showCellVerticalBorder={false}
        localeText={itIT.components.MuiDataGrid.defaultProps.localeText}
        columns={columns}
        rows={recordsFromApi}
        getRowId={(row) => row.newsId}
        initialState={{
          ...recordsFromApi,
          pagination: {
            paginationModel: { pageSize: paginationState, page: 0 },
          },
        }}
        pageSizeOptions={[5, 10, 20, 50]}
        sx={{
          boxShadow:
            "0px 5px 22px rgba(0, 0, 0, 0.05), 0px 0px 0px 0.5px rgba(0, 0, 0, 0.035)",
          borderRadius: 3,
          border: 0,
        }}
        onRowDoubleClick={onCLick}
        slots={{
          toolbar: () => <GridToolbar sx={{ mb: 2 }} />,
        }}
      />
      <ModalPaymentNews
        open={openModal.open}
        id={openModal.id}
        closeModal={closeModal}
        newsData={newsData}
        tokenState={tokenState}
        isAuthState={isAuthState}
        setIsAuthState={setIsAuthState}
        setNewsData={setNewsData}
      />
    </>
  );
};

export default TableSoldNews;
