import React, { useEffect, useMemo, useState } from "react";
import {
  DataGrid,
  itIT,
  GridToolbar,
  GridActionsCellItem,
} from "@mui/x-data-grid";

import { FormattedDate, FormattedNumber } from "react-intl";

import { postRequestGenerator } from "../../helpers/httpHelper";

const TableHistoryNews = ({
  newsData,
  setNewsData,
  tokenState,
  isAuthState,
  setIsAuthState,
  newsStatusState,
}) => {
  const [paginationState, setPaginationState] = useState(10);
  const [loading, setLoading] = useState(false);

  const recordsFromApi = newsData.map((e) => {
    return {
      completeObject: e,
      newsId: e.news.id,
      registrationDate: e.house.registrationDate,
      commercialFlag: e.commercialFlag.visual,
      owner: e.owner.visual,
      ownerContact: e.ownerContact.contact,
      selledPrice: e.house.selledPrice,
      sellingDate: e.news.sellingDate,
      payedCommission: e.news.payedCommission,
      paymentDate: e.news.paymentDate,
    };
  });

  const columns = useMemo(
    () => [
      {
        field: "newsId",
        headerName: "ID",
        width: 66.8,
      },
      {
        field: "registrationDate",
        headerName: "DATA",
        renderCell: (e) => <FormattedDate value={e.value} />,
        width: 80,
      },
      {
        field: "commercialFlag",
        headerName: "SEGNALATORE",
        width: 190,
      },
      {
        field: "owner",
        headerName: "PROPRIETARIO",
        width: 190,
      },
      {
        field: "ownerContact",
        headerName: "CONTATTO",
        width: 140,
      },
      {
        field: "sellingDate",
        headerName: "DATA VENDITA",
        width: 145,

        renderCell: (e) => <FormattedDate value={e.value} />,
      },

      {
        field: "selledPrice",
        headerName: "PREZZO VENDITA",
        width: 170,

        renderCell: (e) => (
          <>
            <FormattedNumber value={e.value} />
            {" €"}
          </>
        ),
      },
      {
        field: "payedCommission",
        headerName: "PROVVIGIONE",
        width: 170,

        renderCell: (e) => (
          <>
            <FormattedNumber value={e.value} />
            {" €"}
          </>
        ),
      },
      {
        field: "paymentDate",
        headerName: "DATA INCASSO",
        width: 135,

        renderCell: (e) => <FormattedDate value={e.value} />,
      },
    ],
    [newsData]
  );

  return (
    <DataGrid
      loading={loading}
      showCellVerticalBorder={false}
      localeText={itIT.components.MuiDataGrid.defaultProps.localeText}
      columns={columns}
      rows={recordsFromApi}
      getRowId={(row) => row.newsId}
      initialState={{
        ...recordsFromApi,
        pagination: {
          paginationModel: { pageSize: paginationState, page: 0 },
        },
      }}
      pageSizeOptions={[5, 10, 20, 50]}
      sx={{
        boxShadow:
          "0px 5px 22px rgba(0, 0, 0, 0.05), 0px 0px 0px 0.5px rgba(0, 0, 0, 0.035)",
        borderRadius: 3,
        border: 0,
      }}
      slots={{
        toolbar: () => <GridToolbar sx={{ mb: 2 }} />,
      }}
    />
  );
};

export default TableHistoryNews;
