import React, { useState } from "react";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  TextField,
  Unstable_Grid2 as Grid,
} from "@mui/material";

import { postRequestGenerator } from "../../helpers/httpHelper";
import ModalInsertEmail from "./ModalInsertEmail";

const CardContactsDetails = ({
  userState,
  tokenState,
  setIsAuthState,
  email,
  telephone,
}) => {
  const [modifiedValuesEmail, setModifiedValuesEmail] = useState({
    id: 0,
    contact: undefined,
  });
  const [modifiedValuesTelephone, setModifiedValuesTelephone] = useState({
    id: 0,
    contact: undefined,
  });
  const [newEmail, setNewEmail] = useState(email);

  const [open, setOpen] = useState(false);
  const [sent, setSent] = useState(false);
  const variant = "outlined";

  const onChangeFieldEmail = (e) => {
    setModifiedValuesEmail({
      ...modifiedValuesEmail,
      [e.target.name]: e.target.value.trim(),
    });
    e.preventDefault();
  };

  const onChangeFieldTelephone = (e) => {
    setModifiedValuesTelephone({
      ...modifiedValuesTelephone,
      [e.target.name]: e.target.value.trim(),
    });
    e.preventDefault();
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const patchTelephone = postRequestGenerator({
      url: `atomic/contacts/${telephone.id}`,
      queryType: "PATCH",
      tokenState: tokenState,
      isAuthState: setIsAuthState,
      body: modifiedValuesTelephone,
    });
    const patchEmail = postRequestGenerator({
      url: `atomic/contacts/${email.id}`,
      queryType: "PATCH",
      tokenState: tokenState,
      isAuthState: setIsAuthState,
      body: modifiedValuesEmail,
    });
    if (modifiedValuesTelephone.contact != undefined) {
      patchTelephone().then(() => setSent(true));
    }
    if (modifiedValuesEmail.contact != undefined) {
      patchEmail().then(() => setSent(true));
    }
    if (
      modifiedValuesTelephone.contact != undefined &&
      modifiedValuesEmail.contact != undefined
    ) {
      Promise.all([patchTelephone(), patchEmail()]).then(() => setSent(true));
    }
  };

  const closeModal = () => {
    setOpen(false);
  };

  return (
    <>
      <Card
        sx={{
          boxShadow:
            "0px 5px 22px rgba(0, 0, 0, 0.05), 0px 0px 0px 0.5px rgba(0, 0, 0, 0.035)",
          borderRadius: 3,
        }}
      >
        {" "}
        <form autoComplete="off" noValidate onSubmit={onSubmit}>
          <CardHeader
            subheader={``}
            title={newEmail !== null ? "contatti" : "recapito"}
            titleTypographyProps={{
              variant: "overline",
              fontSize: "1.15rem",
              color: "#424242",
              mb: "-.30rem",
            }}
            subheaderTypographyProps={{
              variant: "caption",
              fontSize: ".75rem",
              color: "#616161",
              mt: "-.55rem",
              mb: ".0rem",
            }}
          />
          <CardContent sx={{ pt: 0 }}>
            <Box sx={{ m: -1.5 }}>
              <Grid container spacing={2}>
                {newEmail !== null && (
                  <Grid item xs={12} md={12}>
                    <TextField
                      value={
                        modifiedValuesEmail?.contact ?? email?.contact ?? ""
                      }
                      onChange={onChangeFieldEmail}
                      fullWidth={true}
                      color={
                        (modifiedValuesEmail?.contact?.length ?? "") > 0 && sent
                          ? "success"
                          : "primary"
                      }
                      focused={
                        (modifiedValuesEmail?.contact?.length ?? "") > 0 && sent
                      }
                      size="small"
                      type="email"
                      name="contact"
                      label="email"
                      variant={variant}
                    ></TextField>
                  </Grid>
                )}
                {newEmail === null && (
                  <Grid item xs={12} md={12}>
                    <Button
                      variant={"outlined"}
                      color="primary"
                      onClick={() => setOpen(true)}
                    >
                      {"Aggiungi Email"}{" "}
                    </Button>
                  </Grid>
                )}

                <Grid item xs={12} md={12}>
                  <TextField
                    value={
                      modifiedValuesTelephone?.contact ??
                      telephone?.contact ??
                      ""
                    }
                    onChange={onChangeFieldTelephone}
                    fullWidth={true}
                    color={
                      (modifiedValuesTelephone?.contact?.length ?? "") > 0 &&
                      sent
                        ? "success"
                        : "primary"
                    }
                    focused={
                      (modifiedValuesTelephone?.contact?.length ?? "") > 0 &&
                      sent
                    }
                    size="small"
                    type="text"
                    name="contact"
                    label="telefono"
                    variant={variant}
                  ></TextField>
                </Grid>
              </Grid>
            </Box>
          </CardContent>
          <Divider />
          <CardActions sx={{ justifyContent: "flex-end" }}>
            <Button
              type="submit"
              variant={sent ? "outlined" : "contained"}
              sx={{ color: sent ? "primary" : "white" }}
              color="primary"
            >
              {sent ? "SALVATO" : "SALVA"}{" "}
            </Button>
          </CardActions>
        </form>
      </Card>
      <ModalInsertEmail
        open={open}
        closeModal={closeModal}
        userState={userState}
        tokenState={tokenState}
        setIsAuthState={setIsAuthState}
        setNewEmail={setNewEmail}
      />
    </>
  );
};

export default CardContactsDetails;
