import React, { useEffect, useMemo, useState } from "react";
import {
  DataGrid,
  itIT,
  GridToolbar,
  GridActionsCellItem,
} from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import { FormattedDate, FormattedNumber } from "react-intl";
import { newsStatus } from "../../helpers/enums";

import { postRequestGenerator } from "../../helpers/httpHelper";
import TableModifyAction from "../TableDeleteAction";
import TableAcquireAction from "../TableAcquireAction";
import ModalAcquireNews from "./ModalAcquireNews";
import { Typography } from "@mui/material";

const TableInsertedNews = ({
  newsData,
  setNewsData,
  tokenState,
  isAuthState,
  setIsAuthState,
  newsStatusState,
}) => {
  const [paginationState, setPaginationState] = useState(10);
  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState({
    open: false,
    id: undefined,
  });
  const navigate = useNavigate();
  const isVerified = newsStatus.indexOf("Verificata") === newsStatusState;

  const recordsFromApi = newsData.map((e) => {
    return {
      completeObject: e,
      newsId: e.news.id,
      registrationDate: e.house.registrationDate,
      commercialFlag: e.commercialFlag.visual,
      owner: e.owner.visual,
      ownerContact: e.ownerContact.contact,
      requiredPrice: e.house.requiredPrice,
      houseAddress: e.address.visual,
      note: e.news.note,
    };
  });

  const onDelete = (id) => {
    return () => {
      const deleteApi = postRequestGenerator({
        url: `atomic/news/${id}`,
        queryType: "PATCH",
        tokenState: tokenState,
        isAuthState: setIsAuthState,
        body: { isActive: 0 },
      });
      setLoading(true);
      deleteApi().then((e) => {
        setNewsData(newsData.filter((e) => e.news.id !== id));
        setLoading(false);
      });
    };
  };

  const closeModal = () => {
    setOpenModal({
      ...openModal,
      open: false,
    });
  };

  const onAcquire = (id) => {
    return () => {
      setOpenModal({
        open: true,
        id: id,
      });
    };
  };

  const onCLick = (data, e) => {
    e.preventDefault();
    if (!isVerified) {
      navigate(`/gestione-notizie/${data.id}`, {
        state: data.row.completeObject,
      });
    }
  };

  const columns = useMemo(
    () => [
      {
        field: "newsId",
        headerName: "ID",
        width: 66.8,
      },
      {
        field: "registrationDate",
        headerName: "DATA",
        renderCell: (e) => <FormattedDate value={e.value} />,
        width: 80,
      },
      {
        field: "commercialFlag",
        headerName: "SEGNALATORE",
        width: 190,
      },
      {
        field: "owner",
        headerName: "PROPRIETARIO",
        width: 190,
      },
      {
        field: "ownerContact",
        headerName: "CONTATTO",
        width: 140,
      },
      {
        field: "requiredPrice",
        headerName: "RICHIESTA",
        width: 95,
        renderCell: (e) => (
          <>
            <FormattedNumber value={e.value} />
            {" €"}
          </>
        ),
      },
      {
        field: "houseAddress",
        headerName: "INDIRIZZO",
        width: 420,
        maxWidth: 480,
      },
      {
        field: "note",
        headerName: "NOTE SEGNALATORE",
        width: 480,
        maxWidth: 480,
        resizable: true,

        renderCell: (e) => (
          <Typography
            style={{
              whiteSpace: "initial",
              wordWrap: "break-word",
              fontSize: 12,
            }}
          >
            {e.value}
          </Typography>
        ),
      },
      {
        field: "acquisisci",
        type: "actions",
        getActions: (params) => {
          if (isVerified) {
            return [
              <GridActionsCellItem
                icon={<TableAcquireAction />}
                onClick={onAcquire(params.id)}
                label="Acquire"
              />,
              <GridActionsCellItem
                icon={<TableModifyAction />}
                onClick={onDelete(params.id)}
                label="Delete"
              />,
            ];
          } else {
            return [
              <GridActionsCellItem
                icon={<TableModifyAction />}
                onClick={onDelete(params.id)}
                label="Delete"
              />,
            ];
          }
        },
      },
    ],
    [newsData, isVerified]
  );

  return (
    <>
      <DataGrid
        rowHeight={55}
        loading={loading}
        showCellVerticalBorder={false}
        localeText={itIT.components.MuiDataGrid.defaultProps.localeText}
        columns={columns}
        rows={recordsFromApi}
        getRowId={(row) => row.newsId}
        initialState={{
          ...recordsFromApi,
          pagination: {
            paginationModel: { pageSize: paginationState, page: 0 },
          },
        }}
        pageSizeOptions={[5, 10, 20, 50]}
        sx={{
          boxShadow:
            "0px 5px 22px rgba(0, 0, 0, 0.05), 0px 0px 0px 0.5px rgba(0, 0, 0, 0.035)",
          borderRadius: 3,
          border: 0,
        }}
        onRowDoubleClick={onCLick}
        slots={{
          toolbar: () => <GridToolbar sx={{ mb: 2 }} />,
        }}
      />
      <ModalAcquireNews
        open={openModal.open}
        id={openModal.id}
        closeModal={closeModal}
        newsData={newsData}
        tokenState={tokenState}
        isAuthState={isAuthState}
        setIsAuthState={setIsAuthState}
        setNewsData={setNewsData}
      />
    </>
  );
};

export default TableInsertedNews;
