import { Container, SvgIcon } from "@mui/material";
import React from "react";

import TrashIcon from "@heroicons/react/24/outline/TrashIcon";

const TableDeleteAction = () => {
  return (
    <SvgIcon
      color="primary"
      sx={{
        width: "1.35rem",
      }}
    >
      <TrashIcon />
    </SvgIcon>
  );
};

export default TableDeleteAction;
