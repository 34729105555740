import UsersIcon from "@heroicons/react/24/solid/UsersIcon";
import {
  Avatar,
  Card,
  CardContent,
  Stack,
  SvgIcon,
  Typography,
} from "@mui/material";

const CardChildren = (props) => {
  const { sx, personAggregate, graphChildren } = props;
  const children = personAggregate?.people?.taggedPeople ?? graphChildren;

  return (
    <Card sx={sx}>
      <CardContent>
        <Stack
          alignItems="flex-start"
          direction="row"
          justifyContent="space-between"
          spacing={3}
        >
          <Stack spacing={1}>
            <Typography color="text.secondary" variant="overline">
              {graphChildren ? "segnalatori attivi lv.0" : "segnalatori"}
            </Typography>
            <Typography variant="h4">{`${children}`}</Typography>
          </Stack>
          <Avatar
            sx={{
              backgroundColor: "child.main",
              height: 56,
              width: 56,
            }}
          >
            <SvgIcon>
              <UsersIcon />
            </SvgIcon>
          </Avatar>
        </Stack>
      </CardContent>
    </Card>
  );
};

export default CardChildren;
