import UsersIcon from "@heroicons/react/24/solid/BanknotesIcon";
import {
  Avatar,
  Card,
  CardContent,
  Stack,
  SvgIcon,
  Typography,
  Tooltip,
  useTheme,
} from "@mui/material";
import { FormattedNumber } from "react-intl";

const CardPayedGain = (props) => {
  const theme = useTheme();
  const { sx, earnings } = props;
  return (
    <Card sx={sx}>
      <CardContent>
        <Stack
          alignItems="flex-start"
          direction="row"
          justifyContent="space-between"
          spacing={3}
        >
          <Stack spacing={1}>
            <Typography color="text.secondary" variant="overline">
              compensi incassati
            </Typography>
            <Typography variant="h4" color={"#424242"}>
              <FormattedNumber value={earnings.payedGain} />
              {" €"}
            </Typography>
          </Stack>
          <Tooltip title="Totale compensi pagati" placement="top-end">
            <SvgIcon>
              <UsersIcon style={{ color: theme.palette.primary.main }} />
            </SvgIcon>
          </Tooltip>
          {/* <Avatar
            sx={{
              backgroundColor: "childrenInProduction.main",
              height: 56,
              width: 56,
            }}
          >
            <SvgIcon>
              <UsersIcon />
            </SvgIcon>
          </Avatar> */}
        </Stack>
      </CardContent>
    </Card>
  );
};

export default CardPayedGain;
